import DateInput from '@atoms/DateInput/DateInput'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import MultiFileUploadButton from '@atoms/MultiFileUploadButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { setCurrencyChangeEvent } from '@core/utils'
import { fetchUploadFile } from '@services/app.service'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

export const CreateExpenseForm = ({ formId, currencies, onSubmit }) => {
  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const [docs, setDocs] = useState({
    files: [],
    isLoading: false,
    success: false,
    progress: 0,
  })

  const handleUpload = async (file, allFiles) => {
    if (allFiles && allFiles.length > 0) {
      setDocs((prev) => ({
        ...prev,
        isLoading: true,
      }))

      const validFiles = allFiles.filter(
        (f) =>
          f.name !== 'item' &&
          typeof f.name !== 'undefined' &&
          (f.size / 1024 / 1024).toFixed(4) <= 3
      )

      if (validFiles.length < allFiles.length) {
        toast.error(
          'Some files exceed the maximum limit of 3 Mb and were not uploaded.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          }
        )
      }

      if (validFiles.length > 0) {
        const uploadPromises = validFiles.map((currentFile) => {
          return new Promise((resolve) => {
            const setProgress = (progress) => {
              setDocs((prev) => ({
                ...prev,
                isLoading: true,
                progress,
              }))
            }

            fetchUploadFile(currentFile, setProgress)
              .then((result) => {
                resolve({
                  success: true,
                  file: currentFile,
                  uuid: result.file_uuid,
                })
              })
              .catch(() => {
                resolve({
                  success: false,
                  file: currentFile,
                })
              })
          })
        })

        const results = await Promise.all(uploadPromises)
        const successfulUploads = results.filter((r) => r.success)

        if (successfulUploads.length > 0) {
          setDocs((prev) => {
            const updatedFiles = [
              ...prev.files,
              ...successfulUploads.map((r) => ({
                name: r.file.name,
                uuid: r.uuid,
              })),
            ]

            if (updatedFiles.length > 0) {
              setValue('fields', {
                ...getValues('fields'),
                [formId]: {
                  ...getValues('fields')[formId],
                  receipt: updatedFiles[0].uuid,
                  receipts: updatedFiles.slice(1).map((f) => f.uuid),
                },
              })

              clearErrors(`fields.${formId}.receipt`)
            }

            return {
              ...prev,
              files: updatedFiles,
              isLoading: false,
              success: updatedFiles.length > 0,
              progress: 0,
            }
          })
        } else {
          setDocs((prev) => ({
            ...prev,
            isLoading: false,
            progress: 0,
          }))

          toast.error('Failed to upload files. Please try again.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          })
        }
      } else {
        setDocs((prev) => ({
          ...prev,
          isLoading: false,
          progress: 0,
        }))
      }
    } else if (file) {
      const filesize = (file.size / 1024 / 1024).toFixed(4) // MB
      if (
        file.name !== 'item' &&
        typeof file.name !== 'undefined' &&
        filesize <= 3
      ) {
        const setProgress = (progress) => {
          setDocs((prev) => ({
            ...prev,
            isLoading: true,
            progress,
          }))
        }

        fetchUploadFile(file, setProgress)
          .then(({ file_uuid }) => {
            setDocs((prev) => {
              const updatedFiles = [
                ...prev.files,
                { name: file.name, uuid: file_uuid },
              ]

              if (updatedFiles.length === 1) {
                setValue('fields', {
                  ...getValues('fields'),
                  [formId]: {
                    ...getValues('fields')[formId],
                    receipt: file_uuid,
                    receipts: [],
                  },
                })
              } else {
                const receiptsArray = updatedFiles.slice(1).map((f) => f.uuid)
                setValue('fields', {
                  ...getValues('fields'),
                  [formId]: {
                    ...getValues('fields')[formId],
                    receipts: receiptsArray,
                  },
                })
              }

              clearErrors(`fields.${formId}.receipt`)

              return {
                ...prev,
                files: updatedFiles,
                isLoading: false,
                success: true,
                progress: 0,
              }
            })
          })
          .catch(() => {
            setDocs((prev) => ({
              ...prev,
              isLoading: false,
              success: false,
              progress: 0,
            }))
          })
      } else {
        toast.error(
          'Your file size exceeds the maximum limit. Please upload a file no larger than 3 Mb.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          }
        )
      }
    }
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column gap-2">
        <Controller
          control={control}
          name={`fields.${formId}.expense_date`}
          rules={{ required: 'Expense date is required' }}
          render={({ field }) => {
            return (
              <DateInput
                {...field}
                addText="Please indicate the date from the receipt"
                maxDate={new Date()}
                isRequired
                label="Expense Date"
              />
            )
          }}
        />
        {errors.fields?.[formId]?.expense_date && (
          <Typography className="text_regular__14 color_red">
            {errors.fields[formId].expense_date.message}
          </Typography>
        )}

        <div className="row">
          <Typography className="text_medium__14">
            Amount and currency <span className="text-danger ml-1">*</span>
          </Typography>
          <Typography className="text_light__12 color_text_300 mb-2">
            Please indicate the amount and the currency from the receipt
          </Typography>
          <div className="col-6">
            <Controller
              control={control}
              name={`fields.${formId}.amount`}
              rules={{
                required: 'Field is required',
                validate: {
                  minlength: (v) =>
                    /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) ||
                    'Only 2 digits allowed after decimal point',
                },
              }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  placeholder="Enter the amount"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {errors.fields?.[formId]?.amount && (
              <Typography className="text_regular__14 color_red">
                {errors.fields[formId].amount.message}
              </Typography>
            )}
          </div>
          <div className="col-6">
            <div className="w-100 remo-form-input">
              <Controller
                control={control}
                name={`fields.${formId}.currency`}
                rules={{ required: 'Currency is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="create-expense-form.component-9BB79E"
                      {...field}
                      options={currencies.map((country) => ({
                        value: country.id,
                        label: country.name || country.short_code,
                      }))}
                    />
                  )
                }}
              />
              {errors.fields?.[formId]?.currency && (
                <Typography className="text_regular__14 color_red">
                  {errors.fields[formId].currency.message}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <Controller
          name={`fields.${formId}.receipt`}
          control={control}
          render={() => (
            <>
              <MultiFileUploadButton
                id={`receipt_${formId}`}
                label="Upload receipts"
                addText=""
                isRequired
                isLoading={docs.isLoading}
                isSuccess={docs.success}
                progress={docs.progress}
                files={docs.files}
                maxFiles={5}
                accept=".jpg,.jpeg,.png,.pdf"
                onChange={handleUpload}
                onRemove={(index) => {
                  const updatedFiles = [...docs.files]
                  updatedFiles.splice(index, 1)

                  setDocs((prev) => ({
                    ...prev,
                    files: updatedFiles,
                  }))

                  if (updatedFiles.length === 0) {
                    setValue('fields', {
                      ...getValues('fields'),
                      [formId]: {
                        ...getValues('fields')[formId],
                        receipt: '',
                        receipts: [],
                      },
                    })
                    setError(`fields.${formId}.receipt`, {
                      type: 'manual',
                      message: 'Receipt is required',
                    })
                  } else {
                    setValue('fields', {
                      ...getValues('fields'),
                      [formId]: {
                        ...getValues('fields')[formId],
                        receipt: updatedFiles[0].uuid,
                        receipts: updatedFiles.slice(1).map((f) => f.uuid),
                      },
                    })
                  }
                }}
              />
              <Typography className="text_light__12 color_text_300 mt-1">
                You can upload a maximum of 5 receipt files (max 3MB each)
              </Typography>
            </>
          )}
        />
        {errors.fields?.[formId]?.receipt && (
          <Typography className="text_regular__14 color_red">
            {errors.fields[formId].receipt.message}
          </Typography>
        )}
      </div>
    </form>
  )
}
