import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { deleteSelectedAttachment } from '@remoteam-front/services/contract-documents.service'
import {
  ContractDocumentHandlers,
  UploadedAttachment,
} from '@remoteam-front/types/v2'
import { AsyncButton } from '@remoteam-front/ui'
import { Button } from 'react-bootstrap'
import { useMutation } from 'react-query'

type Props = {
  reqDoc: ContractDocumentHandlers
  attachment: UploadedAttachment
  onClose(): void
  onRefetch(): void
}

export const RequiredDocumentAttachmentDelete = ({
  reqDoc,
  attachment,
  onClose,
  onRefetch,
}: Props) => {
  const deleteSelectedUploadMutation = useMutation({
    mutationFn: deleteSelectedAttachment,
    onSuccess: () => {
      onRefetch()
      onClose()
    },
  })

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Delete document</DialogTitle>
      <DialogContent>{attachment.filename}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="secondary">
          Cancel
        </Button>
        <AsyncButton
          variant="outlined"
          color="error"
          isFetching={deleteSelectedUploadMutation.isLoading}
          onClick={() =>
            deleteSelectedUploadMutation.mutate({
              id: reqDoc.id,
              attachmentUuid: attachment.file_uuid,
            })
          }
        >
          Yes, delete document
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
