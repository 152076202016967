import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { uploadDeclinedDocumentHandlers } from '@remoteam-front/services/contract-documents.service'
import { ContractDocumentHandlers } from '@remoteam-front/types/v2'
import { AsyncButton } from '@remoteam-front/ui'
import {
  MultipleUpload,
  UploadFile,
} from '@remoteam-front/ui/components/multiple-upload'
import { useState } from 'react'
import { useMutation } from 'react-query'

type Props = {
  reqDocument: ContractDocumentHandlers
  onRefetch: () => void
  onClose(): void
}

export const RequiredDocumentUpload = ({
  reqDocument,
  onClose,
  onRefetch,
}: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([])

  const showWarning =
    reqDocument.state === 'declined' && reqDocument.attachments.length > 0

  const uploadMutation = useMutation({
    mutationFn: uploadDeclinedDocumentHandlers,
    onSuccess: () => {
      onRefetch()
      onClose()
    },
  })

  const handleSubmit = () => {
    uploadMutation.mutate({
      declined_id: reqDocument.id,
      files: uploadedFiles.map(({ file }) => file),
    })
  }

  const handleDelete = (idToDelete: string) =>
    setUploadedFiles((prev) => prev.filter(({ id }) => id !== idToDelete))

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{reqDocument.employee_document.name}</DialogTitle>
      <DialogContent>
        <MultipleUpload
          helpText="Support for a single or bulk upload"
          files={uploadedFiles}
          onSelectFiles={setUploadedFiles}
          onDelete={handleDelete}
        />
      </DialogContent>
      <DialogActions>
        {showWarning ? (
          <Alert severity="warning">
            <Typography variant="body1">
              By uploading a new document, you confirm that it will replace the
              existing document.
            </Typography>
          </Alert>
        ) : null}
        <Button color="secondary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <AsyncButton
          isFetching={uploadMutation.isLoading}
          variant="contained"
          disabled={!uploadedFiles.length}
          onClick={handleSubmit}
        >
          Save
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
