import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 557px;
  height: 420px;
  background: #40b84c;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PP Nikkei Journal', sans-serif;
  flex-direction: column;
  border-radius: 16px;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 49.28px;
  line-height: 61.66px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1f2120;
  margin-top: 50px;
`

const BadgeWrapper = styled.div`
  width: 200px;
  height: 200px;
  background: #1f2120;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transform: rotate(-5deg);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: 40px;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
`

const BadgeText = styled.div`
  font-weight: 600;
  font-size: 91.72px;
  line-height: 94.97px;
  letter-spacing: -2%;
  text-align: center;
  color: #f4efe2;
`

const SubText = styled.div`
  font-size: 19px;
  font-weight: 600;
  color: #f4efe2;
  text-align: center;
`

const Message = styled.p`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #1f2120;
  margin-top: 20px;
  max-width: 557px;
  text-align: center;
`

const CalculatingProgress = ({ companyCount }) => {
  return (
    <div>
      <Container>
        <Title>Calculating in progress</Title>
        <BadgeWrapper>
          <BadgeText>{companyCount}</BadgeText>
          <SubText>Companies remaining</SubText>
        </BadgeWrapper>
      </Container>

      <Message>
        This process may take a few minutes. Feel free to take a break, stretch,
        and return shortly.
      </Message>
    </div>
  )
}

export default CalculatingProgress
