import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { deleteIconFilled, edit } from '@core/icons/icons'
import { fetchDeletePayroll } from '@services/payroll.service'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { parserPayrollsBasePayment } from '../parse'

const Expandable = styled.td`
  color: #00000073;
  font-weight: 400 !important;
  background-color: #f9f9f9;
  width: 100%;
`

export const NewPayrollExpanded = ({
  payroll,
  setCheckedPayrolls,
  checkedPayrolls,
  setCheckedPayrollsAll,
  onExpand,
}) => {
  const [invoiceId, setInvoiceId] = useState()
  const history = useHistory()
  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)
  const [selectedPayrollDelete, setSelectedPayrollDelete] = useState(null)

  const deleteMutate = useMutation({
    mutationFn: (payrollId) => fetchDeletePayroll(payrollId),
    onSuccess: () => {
      // refetch()
      toggleDelete()
      toast.success('Payroll successfully deleted')
    },
  })

  const onCollapseClick = useCallback((row) => {
    setInvoiceId(row.id)
  }, [])
  const onClickEdit = useCallback((rowData) => {
    history.push(`/pages/payrolls/edit/${rowData.id}`)
  }, [])
  const handleDelete = (row) => {
    toggleDelete()
    setSelectedPayrollDelete(row.id)
  }
  const handleCheck = (e) => {
    if (checkedPayrolls && checkedPayrolls[e.target.name]) {
      const allPayrolls = checkedPayrolls
      delete allPayrolls[e.target.name]
      if (Object.keys(allPayrolls).length === 0) {
        setCheckedPayrolls(null)
        setCheckedPayrollsAll(true)
      } else {
        setCheckedPayrolls(allPayrolls)
        setCheckedPayrollsAll(false)
      }
    } else
      setCheckedPayrolls((prev) => ({
        ...prev,
        [e.target.name]: e.target.name,
      }))
  }
  const multiCurrencyPayrollsField = useMemo(() => {
    return [
      {
        title: 'Employee',
        key: 'Employee',
        type: 'custom',
        maxWidth: 250,
        minWidth: 250,
        width: 250,
        render: (item) => (
          <div style={{ fontWeight: 500 }}>
            {item?.user?.full_name}
            {item.indication !== 'regular' && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    {item.indication === 'first'
                      ? `First payroll of the employee starting on ${
                          item?.job?.starts_at
                            ? moment(item?.job?.starts_at).format('DD MMM YYYY')
                            : ''
                        }`
                      : `Last payroll of the employee terminating on ${
                          item?.job?.ends_at
                            ? moment(item?.job?.ends_at).format('DD MMM YYYY')
                            : ''
                        }`}
                  </Tooltip>
                }
              >
                <span
                  className={`text_regular__14 ${
                    item.indication === 'first' ? 'color_blue' : 'color_red'
                  }`}
                  style={{
                    marginLeft: 4,
                    verticalAlign: 'top',
                    padding: 4,
                    border: `1px solid ${
                      item.indication === 'first' ? '#2967bc' : '#ef4343'
                    }`,
                  }}
                >
                  {item.indication === 'first'
                    ? 'First payroll'
                    : 'Terminating'}
                </span>
              </OverlayTrigger>
            )}
          </div>
        ),
      },

      {
        title: 'Total',
        key: 'gross_salary',
        type: 'string',
        maxWidth: 156,
        minWidth: 156,
        width: 156,
      },
      {
        title: 'Status',
        key: 'state',
        type: 'custom',
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        render: (item) => <Status status={`flag_payroll_${item.state}`} />,
      },
      {
        title: 'Actions',
        key: 'actions',
        type: 'custom',
        render: (item) => {
          return (
            <>
              <Button
                priority="secondary"
                style={{
                  height: '30px',
                  minWidth: '30px',
                  padding: '0 !important',
                }}
                size="small"
                className="px-2 mr-2 pl-0"
                onClick={() => onClickEdit(item)}
              >
                <Icon fill="none" icon={edit} />
              </Button>
              <Button
                priority="secondary"
                style={{
                  height: '30px',
                  minWidth: '30px',
                  padding: '0 !important',
                }}
                size="small"
                className="px-2 mr-2 pl-0"
                onClick={() => handleDelete(item)}
              >
                <Icon icon={deleteIconFilled} />
              </Button>
              <Checkbox
                id={item.id}
                name={item.id}
                disabled={item.state === 'approved_by_partner'}
                checked={checkedPayrolls && !!checkedPayrolls[item.id]}
                onChange={handleCheck}
              />
            </>
          )
        },
        maxWidth: 160,
        minWidth: 160,
        width: 160,
      },
    ]
  }, [checkedPayrolls])
  return (
    <>
      <Expandable colSpan={7}>
        {payroll && (
          <CustomTable
            fields={multiCurrencyPayrollsField}
            type="collapse"
            onCollapseClick={onCollapseClick}
            data={parserPayrollsBasePayment(payroll)}
          />
        )}
      </Expandable>
      <EditModal
        visible={visibleDelete}
        title="Delete payroll"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to delete this payroll?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedPayrollDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>
    </>
  )
}
