import Button from '@atoms/Button/Button'
import NotFound from '@atoms/NotFound/NotFound'
import Typography from '@atoms/Typography/Typography'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { MultiCurrencyMainStatus } from '@pages/multi-currency/multi-currency-status/multi-currency-status.component'
import { splitNumber } from '@remoteam-front/utils'
import { mapCurrency } from '@remoteam-front/utils/currency'
import { approveFlowPayroll, getPayrollsTotal } from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { MultiCurrencyExpanded } from './multi-currency-expanded.component'

const Item = ({
  company,
  totalAmount,
  refetchApproved,
  isDisabled,
  totalAmountInPartnerInvoicingCurrency,
  localCurrencyCode,
  invoiceCurrencyCode,
  localCurrency,
  invoiceCurrency,
  refetch,
  isApproved,
}) => {
  const { params, setParams } = useSearchParams()

  const [expandData, setExpandData] = useState(params.get('companyId') || null)
  const [checkedPayrolls, setCheckedPayrolls] = useState(null)
  const [checkedPayrollsAll, setCheckedPayrollsAll] = useState(true)
  const isOpen = Number(expandData) === company?.company_id

  const approvePayrollRequest = useMutation('approveFlowPayroll', {
    mutationFn: (payload) => approveFlowPayroll(payload),
    onSuccess: () => {
      toast.success('Successfully updated')
      setCheckedPayrolls(null)
      refetch()
      refetchApproved()
    },
  })
  const handleClick = (company) => () => {
    if (isOpen) {
      setParams({ companyId: '' })
    } else {
      setParams({ companyId: company.company_id })
    }
    setExpandData(isOpen ? null : company.company_id)
  }
  const handleClickApprove = () => {
    if (checkedPayrolls) {
      approvePayrollRequest.mutate({
        payrolls: Object.keys(checkedPayrolls),
      })
    }
  }

  const handleCheckCountPayroll = () => {
    if (checkedPayrolls) {
      if (checkedPayrollsAll) {
        if (
          company?.payrolls.filter((p) => p.state === 'created').length ===
          Object.keys(checkedPayrolls).length
        ) {
          return 'Approve company'
        }
        return 'Approve'
      }
      return 'Approve'
    }
    return 'Approve'
  }
  return (
    <>
      <tr key={company.company_id}>
        <td className="td_name">{company.company_name}</td>
        <td className="">{company.current_month}</td>
        <td className="">
          {company.total_count - company.count}/{company.total_count}
        </td>
        <td className="">
          {localCurrency} {splitNumber(totalAmount)}
        </td>
        <td className="">
          {invoiceCurrency} {splitNumber(totalAmountInPartnerInvoicingCurrency)}
        </td>
        {!isApproved && (
          <td className="">
            <MultiCurrencyMainStatus status={company.status} />
          </td>
        )}
        <td style={{ width: '180px' }} className="">
          <Button
            priority="secondary"
            style={{ height: '32px' }}
            size="small"
            onClick={handleClick(company)}
            className="px-2 pl-0 mr-2"
          >
            {isOpen ? 'Hide' : 'Expand'}
          </Button>
          {isOpen && company.status !== 'approved' && (
            <Button
              style={{ height: '32px' }}
              size="small"
              disabled={!checkedPayrolls || isDisabled}
              onClick={handleClickApprove}
              className="px-2 pl-0"
            >
              {handleCheckCountPayroll()}
            </Button>
          )}
        </td>
      </tr>
      <Body open={isOpen}>
        <MultiCurrencyExpanded
          setCheckedPayrollsAll={setCheckedPayrollsAll}
          checkedPayrolls={checkedPayrolls}
          setCheckedPayrolls={setCheckedPayrolls}
          localCurrency={localCurrency}
          invoiceCurrency={invoiceCurrency}
          localCurrencyCode={localCurrencyCode}
          invoiceCurrencyCode={invoiceCurrencyCode}
          payroll={company?.payrolls}
          isApproved={isApproved}
        />
      </Body>
    </>
  )
}

export const MultiCurrencyTable = ({
  data,
  refetch,
  isDisabled,
  isLoading,
  refetchApproved,
  isApproved,
}) => {
  const { data: dataTotal, isLoading: isLoadingTotals } = useQuery(
    ['getPayrollsTotal'],
    {
      queryFn: () => getPayrollsTotal(),
    }
  )
  return (
    <Card>
      {isLoading ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <Table isApproved>
          <thead>
            <tr>
              <th>Company</th>
              <th>Month</th>
              <th>Approved employees</th>
              <th>Local Currency Total</th>
              <th>Invoice currency total</th>
              {!isApproved && <th>Status</th>}
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.length > 0 ? (
              data?.data?.map((company) => (
                <Item
                  key={company.company_id}
                  company={company}
                  refetch={refetch}
                  refetchApproved={refetchApproved}
                  totalAmount={company.total_amount}
                  totalAmountInPartnerInvoicingCurrency={
                    company.total_in_partner_invoicing_currency
                  }
                  isDisabled={isDisabled}
                  localCurrencyCode={data?.partner_default_currency?.short_code}
                  invoiceCurrencyCode={
                    data?.partner_invoicing_currency?.short_code
                  }
                  localCurrency={mapCurrency(data?.partner_default_currency)}
                  invoiceCurrency={mapCurrency(
                    data?.partner_invoicing_currency
                  )}
                  isApproved={isApproved}
                />
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <NotFound
                    illustration="/assets/img/payrollEmpty.png"
                    title="No payrolls yet"
                    description="They will appear here in the next billing period"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
      {dataTotal && (
        <div className="payroll-bottom-wrapper">
          <div className="payroll-bottom d-flex justify-content-center">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Total of current payroll cycle:{' '}
                  {mapCurrency(dataTotal.partner_currency)}{' '}
                  {splitNumber(dataTotal?.total_payroll_amount)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.total_companies_count} companies
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Already approved: {mapCurrency(dataTotal.partner_currency)}{' '}
                  {splitNumber(dataTotal?.approved_payroll_amount)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.approved_payroll_companies_count} companies
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Pending: {mapCurrency(dataTotal.partner_currency)}{' '}
                  {splitNumber(dataTotal?.pending_payroll_amount)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.pending_payroll_companies_count} companies
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}

const Table = styled('table')`
  .actions {
    width: 20px;
  }
  .breakdown {
    width: 300px;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    width: 150px;
  }
  th {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #878787;
  }
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .td_name {
    font-weight: 500;
  }
  th:nth-child(1) {
    width: 125px;
  } // Company
  th:nth-child(2) {
    width: 90px;
  } // Month
  th:nth-child(3) {
    width: 50px;
  } // Approved employees
  th:nth-child(4),
  th:nth-child(5) {
    // Currency columns
    width: 150px;
  }
  th:nth-child(6) {
    width: 150px;
  } // Status
  th:nth-child(7) {
    width: 180px;
  } // Details
  & > tbody > tr > td:nth-child(1) {
    width: 125px;
    padding: 24px 48px;
  } // Company
  td:nth-child(2) {
    width: 90px;
  } // Month
  td:nth-child(3) {
    width: 50px;
  } // Approved employees
  td:nth-child(4),
  td:nth-child(5) {
    // Currency columns
    width: 150px;
  }
  td:nth-child(6) {
    width: 150px;
  } // Status
  td:nth-child(7) {
    width: 180px;
  } // Details
`

const Card = styled.div`
  border-radius: 8px;
  height: fit-content;
`

const Body = styled.tr`
  padding: 16px;
  background: #f9f9f9;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'table-row' : 'none')};
`
