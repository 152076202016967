import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { attachFile, deleteIcon } from '@core/icons/icons'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'

import styles from './styles.module.scss'

const Label = ({ label, addText, isRequired }) => (
  <>
    {label && (
      <label className="remo-form-label" htmlFor="file-input">
        {label} {isRequired && <span className="text-danger ml-1">*</span>}
      </label>
    )}
    {addText && (
      <Typography
        className="text_light__12 color_text_300 mt-1"
        style={{ marginBottom: 8 }}
      >
        {addText}
      </Typography>
    )}
  </>
)

const FileItem = ({ file, index, onRemove }) => (
  <div className={styles.fileItem}>
    <div className={styles.fileInfo}>
      <div className={styles.fileIconContainer}>
        <Icon icon={attachFile} className={styles.fileIcon} />
      </div>
      <div className={styles.fileDetails}>
        <span className={styles.fileName} title={file.name}>
          {file.name}
        </span>
        {file.size && (
          <span className={styles.fileSize}>
            ({Math.round(file.size / 1024)}Kb)
          </span>
        )}
      </div>
    </div>
    <button
      type="button"
      className={styles.deleteButton}
      onClick={() => onRemove(index)}
      aria-label="Delete file"
    >
      <Icon icon={deleteIcon} />
    </button>
  </div>
)

const MultiFileUploadButton = React.forwardRef(
  (
    {
      id,
      onChange,
      onRemove,
      label,
      addText,
      progress,
      isLoading,
      isRequired,
      accept = '',
      maxFiles = 5,
      files = [],
      name,
    },
    ref
  ) => {
    const [localFiles, setLocalFiles] = useState(files)

    const handleRemove = (index) => {
      const updatedFiles = [...localFiles]
      updatedFiles.splice(index, 1)
      setLocalFiles(updatedFiles)
      if (onRemove) onRemove(index)
    }

    const handleFileInput = useCallback(
      (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          const newFiles = [...localFiles]
          acceptedFiles.forEach((file) => {
            if (newFiles.length < maxFiles) {
              newFiles.push(file)
            }
          })
          setLocalFiles(newFiles)
          if (acceptedFiles[0]) {
            onChange(acceptedFiles[0], newFiles)
          }
        }
      },
      [localFiles, maxFiles, onChange]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleFileInput,
      accept: accept.split(',').reduce((acc, curr) => {
        acc[curr] = []
        return acc
      }, {}),
      maxFiles: maxFiles - localFiles.length,
      disabled: localFiles.length >= maxFiles,
    })

    const renderFileList = () => {
      if (!localFiles || localFiles.length === 0) return null
      return (
        <div className={styles.fileList}>
          {localFiles.map((file, index) => (
            <FileItem
              key={file.name || `file-${Date.now()}-${Math.random()}`}
              file={file}
              index={index}
              onRemove={handleRemove}
            />
          ))}
        </div>
      )
    }

    if (isLoading) {
      return (
        <>
          <Label label={label} addText={addText} isRequired={isRequired} />
          <div className={styles.dropzone}>
            <ProgressBar
              now={progress}
              label={<Typography>{progress}%</Typography>}
            />
          </div>
          {renderFileList()}
        </>
      )
    }

    const acceptedFileTypes = accept
      ? `${accept.replace(/\./g, '').replace(/,/g, ', ')} files up to 50 MB`
      : 'Files up to 50 MB'

    const isDropzoneDisabled = localFiles.length >= maxFiles
    const dropzoneMessage = isDropzoneDisabled
      ? 'Maximum number of files reached'
      : 'Drag and drop your file(s) here'

    return (
      <>
        <Label label={label} addText={addText} isRequired={isRequired} />
        <div
          {...getRootProps()}
          className={classNames(styles.dropzone, {
            [styles.dragActive]: isDragActive,
            [styles.disabled]: isDropzoneDisabled,
          })}
        >
          <div className={styles.dropzoneContent}>
            <Typography className={styles.dropzoneText}>
              {dropzoneMessage}
            </Typography>
            <Typography className={styles.dropzoneSubtext}>
              {!isDropzoneDisabled && (
                <>
                  {acceptedFileTypes}
                  {localFiles.length > 0 && (
                    <span>
                      {' '}
                      ({localFiles.length}/{maxFiles})
                    </span>
                  )}
                </>
              )}
            </Typography>
          </div>
          {!isDropzoneDisabled && (
            <button
              type="button"
              className={styles.selectFileButton}
              onClick={(e) => {
                e.stopPropagation()
                document.getElementById(`file-input_${id}`).click()
              }}
            >
              Select file
            </button>
          )}
          <input
            {...getInputProps()}
            data-testid="MultiFileUploadButton-index-0D2BC8"
            ref={ref}
            name={name}
            id={`file-input_${id}`}
          />
        </div>
        {renderFileList()}
      </>
    )
  }
)

export default MultiFileUploadButton
