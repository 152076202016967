/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { AppContext } from '@core/context'
import {
  effectiveDateHistoryOptions,
  filterDateHistoryOptions,
} from '@pages/employeePayslips/payslips.utils'
import { SingleUpload } from '@remoteam-front/ui/components/single-upload'
import { getContractByIdPayslips } from '@services/document.service'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 570px;
    border-radius: 0 !important;
  }
`

export default ({ onClose, onSubmit, loading, title, employee, isPending }) => {
  const [documentRequire, setDocumentRequire] = useState(false)
  const { profile } = useContext(AppContext)

  const [doc, setDoc] = useState({
    file_uuid: undefined,
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contract: employee.id,
      document_type: 'payslip',
      payslip_for_date: isPending
        ? effectiveDateHistoryOptions(isPending).shift()
        : undefined,
      file: undefined,
    },
  })

  const watchPayslipDate = watch('payslip_for_date')
  const { data: payslipsResponse } = useQuery(
    ['getContractByIdPayslipsInMonth', employee.id, watchPayslipDate],
    {
      queryFn: () =>
        getContractByIdPayslips({
          contract_id: employee.id,
          month: watchPayslipDate.month,
          year: watchPayslipDate.year,
          document_type: 'payslip',
          // year: new Date().getUTCFullYear(),
        }),
      enabled: !!watchPayslipDate?.month,
    }
  )

  const handleUploadAgreement = (formValues) => {
    if (!doc.file) {
      setDocumentRequire(true)
      return
    }

    const fd = new FormData()
    fd.append('file', doc.file)
    fd.append('name', doc.file.name)
    fd.append('contract', formValues.contract?.value || formValues.contract)
    fd.append(
      'payslip_for_date',
      formValues.payslip_for_date?.value || formValues.payslip_for_date
    )
    fd.append('document_type', 'payslip')
    onSubmit(fd)
  }

  const handleUpload = async ({ file }) => {
    setDocumentRequire(false)

    const filesize = (file.size / 1024 / 1024).toFixed(4) // MB
    if (
      file.name !== 'item' &&
      typeof file.name !== 'undefined' &&
      filesize <= 3
    ) {
      setDoc((prev) => ({
        ...prev,
        file,
        success: true,
      }))
    } else {
      toast.error(
        'Your fle size exceeds the maximum limit. Please upload a file no larger than 3 Mb.',
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        }
      )
    }
  }

  const handleRemove = () => () => {
    setDoc({})
  }
  return (
    <StyledModal show onHide={onClose} centered className="p-0">
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4">
        <form id="expense-form" onSubmit={handleSubmit(handleUploadAgreement)}>
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contract"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="upload-paylips-modal-104E51"
                    {...field}
                    isRequired
                    label="Employee"
                    isDisabled
                    placeholder="Select the employee"
                    defaultValue={employee.id}
                    options={[
                      {
                        label: employee.name,
                        value: employee.id,
                      },
                    ]}
                  />
                )
              }}
            />
            {errors.contract && (
              <Typography className="text_regular__14 color_red">
                {errors.contract.message}
              </Typography>
            )}
          </div>
          {!isPending && (
            <div className="w-100 remo-form-input">
              <Controller
                control={control}
                name="payslip_for_date"
                rules={{ required: 'Payslip for the month is required' }}
                render={({ field }) => (
                  <Select
                    data-testid="upload-paylips-modal-13B389"
                    {...field}
                    label="Payslip for the month of"
                    isRequired
                    isDisabled={isPending}
                    addText="Select the month for which you would like to upload the payslip"
                    options={filterDateHistoryOptions(
                      employee.original.job.starts_at,
                      employee.original.job.ends_at
                    )}
                  />
                )}
              />
              {errors.payslip_for_date && (
                <Typography className="text_regular__14 color_red">
                  {errors.payslip_for_date.message}
                </Typography>
              )}
            </div>
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {payslipsResponse ? (
            payslipsResponse.count === profile?.number_of_payments_per_month ? (
              <NotificationBlock
                isError
                text={`You have uploaded ${payslipsResponse.count} out of ${
                  profile?.number_of_payments_per_month
                } required payslips for
                 the employee for ${
                   moment.months()[watchPayslipDate.month - 1]
                 } ${
                  watchPayslipDate.year
                }. Please note that the maximum number of files for the selected month has been reached and
                 further uploads are not possible.`}
              />
            ) : (
              <NotificationBlock
                text={`You have uploaded ${payslipsResponse.count} out of ${
                  profile?.number_of_payments_per_month
                } required payslips for the
                 employee for ${moment.months()[watchPayslipDate.month - 1]} ${
                  watchPayslipDate.year
                }. You can still upload ${
                  profile.number_of_payments_per_month - payslipsResponse.count
                } more files for the selected month`}
              />
            )
          ) : null}
          {payslipsResponse?.count !== profile.number_of_payments_per_month ? (
            <SingleUpload
              accept="application/pdf, application/msword, .png, .jpg, .jpeg, .doc,.docx"
              label="Upload payslip"
              isRequired
              helpText={doc.description}
              isSuccess={doc.success}
              isLoading={doc.isLoading}
              file={doc.file}
              onDelete={handleRemove()}
              onSelect={handleUpload}
            />
          ) : null}
          {documentRequire && (
            <Typography className="text_regular__14 color_red mt-2">
              Document required
            </Typography>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="upload-paylips-modal-F58C94"
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="upload-paylips-modal-41177D"
          form="expense-form"
          type="submit"
          size="small"
          loading={loading}
          priority="primary"
        >
          Upload
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
