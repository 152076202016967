/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Icon from '@atoms/Icon/Icon'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { useApp } from '@core/context'
import { deleteIcon } from '@core/icons/icons'
import { setCurrencyChangeEvent } from '@core/utils'
import { fetchCompanies } from '@pages/customers/CustomersPage.service'
import { fetchContractorsByCompany } from '@pages/employees/EmployeesPage.service'
import { employeeTableParse } from '@pages/employees/parse'
import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { fetchCurrencies, fetchUploadFile } from '../../services/app.service'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 570px;
    border-radius: 0 !important;
  }
`
export const categoryTypes = [
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'COWORKING_SPACE', label: 'Coworking space' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'FOOD', label: 'Food' },
  { value: 'OFFICE_SUPPLIES', label: 'Office supplies' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'OTHER', label: 'Other' },
]

export const UpdateExpenseModal = ({
  onClose,
  onSubmit,
  loading,
  isEdit,
  title,
  expense,
}) => {
  const [doc, setDoc] = useState({
    file_uuid: expense?.filename || undefined,
  })
  const [documentRequire, setDocumentRequire] = useState(false)
  const [contracts, setContracts] = useState([])
  const [companies, setCompanies] = useState([])
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      contract: expense?.contract.id || undefined,
      expense_date: expense?.expense_date
        ? new Date(expense.expense_date)
        : undefined,
      expense_name: expense?.expense_name || undefined,
      amount: expense?.amount || undefined,
      currency: expense?.currency.id || undefined,
      category: expense?.category || undefined,
      description: expense?.description || undefined,
    },
  })

  const category_watch = watch('category')
  const companyWatch = watch('company')
  const expenseNameWatch = watch('expense_name')
  const description = watch('description')
  const contract = watch('contract')
  const expenseDate = watch('expense_date')
  const amountWatch = watch('amount')
  const currencyWatch = watch('currency')
  const { isLoading: isLoadingCompany } = useQuery(['companies'], {
    queryFn: () => fetchCompanies('active,onboarding', undefined, 1, 1000),
    onSuccess: ({ results }) => {
      setCompanies(results)
    },
  })
  const { isLoading: loadingCurrency, data: currencies } = useQuery(
    'fetchCurrenciesExpense',
    {
      queryFn: () =>
        fetchCurrencies({ limit: 1000, offset: 0, ordering: 'short_code' }),
    }
  )
  const { isLoading } = useQuery(['contractors', companyWatch], {
    queryFn: () =>
      fetchContractorsByCompany(
        companyWatch.value ?? companyWatch,
        undefined,
        1,
        '&state=active',
        1000
      ),
    onSuccess: ({ results }) => {
      setContracts(employeeTableParse(results))
    },
    enabled: !!companyWatch,
  })
  const checkAllRequire = useMemo(() => {
    return !!(
      currencyWatch &&
      contract &&
      amountWatch &&
      category_watch &&
      expenseNameWatch &&
      description &&
      expenseDate
    )
  }, [
    currencyWatch,
    contract,
    category_watch,
    amountWatch,
    expenseNameWatch,
    description,
    expenseDate,
  ])
  const handleCreateTimeOff = (formValues) => {
    const data = {
      ...formValues,
      receipt: doc.file_uuid === expense?.filename ? undefined : doc.file_uuid,
      expense_date: moment(formValues.expense_date).format('YYYY-MM-DD'),
      contract: formValues.contract.value || formValues.contract,
      currency: formValues.currency.value || formValues.currency,
      category: formValues.category.value || formValues.category,
    }
    if (!doc.file_uuid) {
      setDocumentRequire(true)
    } else {
      onSubmit(data)
    }
  }

  const handleUpload = async (file) => {
    setDocumentRequire(false)

    const filesize = (file.size / 1024 / 1024).toFixed(4) // MB
    if (
      file.name !== 'item' &&
      typeof file.name !== 'undefined' &&
      filesize <= 3
    ) {
      const setProgress = (progress) => {
        setDoc((prev) => ({
          ...prev,
          isLoading: true,
          progress,
        }))
      }

      fetchUploadFile(file, setProgress)
        .then(({ file_uuid }) => {
          setDoc((prev) => ({
            ...prev,
            file_uuid,
            isLoading: false,
            success: true,
            progress: 0,
          }))
        })
        .catch(() => {
          setDoc((prev) => ({
            ...prev,
            isLoading: false,
            success: false,
            progress: 0,
          }))
        })
    } else {
      toast.error(
        'Your fle size exceeds the maximum limit. Please upload a file no larger than 3 Mb.',
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        }
      )
    }
  }

  const handleRemove = () => () => {
    setDoc({})
  }
  const handleDelete = () => {
    setDoc({
      file_uuid: undefined,
    })
  }

  useEffect(() => {
    if (companies?.length === 1) {
      setValue('company', companies[0].id)
    }
  }, [companies, setValue])

  useEffect(() => {
    if (expense) {
      setValue('company', {
        value: expense.contract.company_id,
        label: expense.contract.company_name,
      })
    }
  }, [expense, setValue])

  useEffect(() => {
    if (isEdit) {
      if (contract !== expense?.contract?.id) {
        setValue('contract', '')
      }
    } else {
      setValue('contract', '')
    }
  }, [expense, companyWatch, isEdit, setValue])

  return (
    <ModalSide
      title={title}
      okButtonProps={{
        disabled: !checkAllRequire,
        loading,
        form: 'expense-form',
        type: 'submit',
      }}
      okText={isEdit ? 'Update & Send' : 'Create'}
      onClose={onClose}
    >
      <form id="expense-form" onSubmit={handleSubmit(handleCreateTimeOff)}>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="company"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="UpdateExpenseModal-381D9A"
                  {...field}
                  isDisabled={isLoadingCompany}
                  isRequired
                  addText="You can choose only active companies from the list"
                  label={isLoadingCompany ? 'Loading...' : 'Select company'}
                  options={companies.map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))}
                />
              )
            }}
          />
          {errors.company && (
            <Typography className="text_regular__14 color_red">
              {errors.company.message}
            </Typography>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="contract"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="UpdateExpenseModal-E690AA"
                  {...field}
                  isDisabled={!companyWatch || isLoading}
                  isRequired
                  addText="You can choose only active workers from the list"
                  label={isLoading ? 'Loading...' : 'Select worker'}
                  options={contracts.map((contract) => ({
                    value: contract.id,
                    label: contract.full_name,
                  }))}
                />
              )
            }}
          />
          {errors.contract && (
            <Typography className="text_regular__14 color_red">
              {errors.contract.message}
            </Typography>
          )}
        </div>
        <div className="row mb-3">
          <div className="col pr-2">
            <Controller
              control={control}
              name="category"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="UpdateExpenseModal-108015"
                    isRequired
                    {...field}
                    label="Category"
                    options={categoryTypes}
                  />
                )
              }}
            />
            {errors.category && (
              <Typography className="text_regular__14 color_red">
                {errors.category.message}
              </Typography>
            )}
          </div>
        </div>
        <div className="remo-form-input mt-2">
          <Input
            data-testid="UpdateExpenseModal-3E2FA1"
            {...register('expense_name', {
              required: 'Field is required',
            })}
            isRequired
            type="text"
            label="Expense name"
            placeholder="Enter expense name"
          />
          {errors.expense_name && (
            <Typography className="text_regular__14 color_red">
              {errors.expense_name.message}
            </Typography>
          )}
        </div>
        <TextArea
          isRequired
          label="Description"
          placeholder="Provide more details"
          {...register('description')}
        />
        {errors.description && (
          <Typography className="text_regular__14 color_red">
            {errors.description.message}
          </Typography>
        )}
        <div className="w-100 remo-form-input mt-3">
          <Controller
            control={control}
            name="expense_date"
            rules={{ required: 'Expense date is required' }}
            render={({ field }) => {
              return (
                <DateInput
                  {...field}
                  addText="Please indicate the date from the receipt"
                  maxDate={new Date()}
                  isRequired
                  label="Expense Date"
                />
              )
            }}
          />
          {errors.expense_date && (
            <Typography className="text_regular__14 color_red">
              {errors.expense_date.message}
            </Typography>
          )}
        </div>
        <div className="row">
          <Typography className="text_medium__14">
            Amount and currency <span className="text-danger ml-1">*</span>
          </Typography>
          <Typography className="text_light__12 color_text_300 mb-2">
            Please indicate the amount and the currency from the receipt
          </Typography>
          <div className="col-6">
            <Controller
              control={control}
              name="amount"
              rules={{
                required: 'Field is required',
                validate: {
                  minlength: (v) =>
                    /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) ||
                    'Only 2 digits allowed after decimal point',
                },
              }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  placeholder="Enter the amount"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {errors.amount && (
              <Typography className="text_regular__14 color_red">
                {errors.amount.message}
              </Typography>
            )}
          </div>
          <div className="col-6">
            <div className="w-100 remo-form-input">
              <Controller
                control={control}
                name="currency"
                rules={{ required: 'Currency is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="UpdateExpenseModal-013A83"
                      {...field}
                      loading={loadingCurrency}
                      options={currencies?.results?.map((country) => ({
                        value: country.id,
                        label: country.name || country.short_code,
                      }))}
                    />
                  )
                }}
              />
              {errors.currency && (
                <Typography className="text_regular__14 color_red">
                  {errors.currency.message}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <NotificationBlock text="Note that reimbursements will be made in the currency of the worker's employment agreement regardless of the currency in the receipt" />
        {doc.file_uuid && expense?.receipt ? (
          <div className="d-flex justify-content-between align-items-center mt-3">
            <Typography as="span" className="text_medium__14">
              {expense?.filename}
            </Typography>
            <Button
              data-testid="UpdateExpenseModal-13CF18"
              priority="secondary"
              style={{ height: '32px' }}
              size="small"
              className="px-2 ml-3"
              onClick={handleDelete}
            >
              <Icon fill="none" icon={deleteIcon} />
              <Typography className={classNames('text_light__12', 'ml-2')}>
                Delete
              </Typography>
            </Button>
          </div>
        ) : (
          <UploadButton
            addText={doc.description}
            isLoading={doc.isLoading}
            isSuccess={doc.success}
            label="Upload receipt"
            isRequired
            progress={doc.progress}
            onRemove={handleRemove()}
            onChange={(_file) => handleUpload(_file)}
            accept="application/pdf, application/msword, .png, .jpg, .jpeg, .doc,.docx"
          />
        )}
        {documentRequire && (
          <Typography className="text_regular__14 color_red mt-2">
            Document required
          </Typography>
        )}
      </form>
    </ModalSide>
  )
}
