import { styled } from '@mui/material'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  '& .secondary-variant': {
    color: theme.palette.secondary[400],
  },
}))

const Upload = styled('div')<{ isOver: boolean }>(({ theme, isOver }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  background: theme.palette.secondary[900],
  border: `${isOver ? 'solid' : 'dashed'} 1px`,
  borderRadius: theme.spacing(0.5),
  borderColor: isOver ? theme.palette.primary.main : '#B7C3CC',
  alignItems: 'center',
}))

const Files = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const File = styled('div')(({ theme }) => ({
  background: theme.palette.product.background2,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1, 1.5),
}))

export const Styled = { Root, Upload, Files, File }
