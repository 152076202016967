import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'
import React, { DragEvent, forwardRef, useState } from 'react'
import { v4 } from 'uuid'

import { DeleteIcon, FileMiniActive } from '../../icons/icons'
import { cyan } from '../../theme/colors'
import { Styled } from './multiple-upload.styles'

export type UploadFile = {
  id: string
  file: File
  isLoading: boolean
  isSuccess: boolean
  isDeleted: boolean
}

export type Files = Array<UploadFile>

type Props = {
  files: Files
  onSelectFiles(files: Files): void
  onDelete(id: string): void
  onUndo?(id: string): void
  helpText?: string
} & Omit<React.HTMLProps<HTMLInputElement>, 'multiple'>

const MultipleUpload = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    files = [],
    onSelectFiles,
    onDelete,
    onUndo,
    helpText,
    ...rest
  } = props

  const [isOver, setIsOver] = useState<boolean>(false)

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOver(true)
  }
  const handleDragLeave = () => {
    setIsOver(false)
  }

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const files = Array.from(event.dataTransfer.files)
    setIsOver(false)
    onSelectFiles(
      files.map((file) => ({
        id: v4(),
        file,
        isDeleted: false,
        isLoading: false,
        isSuccess: false,
      }))
    )
  }

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (files) {
      onSelectFiles(
        Array.from(files).map((file) => ({
          id: v4(),
          file,
          isDeleted: false,
          isLoading: false,
          isSuccess: false,
        }))
      )
      // reset value to allow select the same file again
      // eslint-disable-next-line no-param-reassign
      event.target.value = ''
    }
  }

  const handleDelete = (id: string) => () => {
    onDelete(id)
  }

  const handleUndo = (id: string) => () => {
    if (onUndo) onUndo(id)
  }

  return (
    <Styled.Root>
      <Styled.Upload
        isOver={isOver}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="body2">Drag and drop your files here</Typography>
          <Typography variant="textSmall1" className="secondary-variant">
            {helpText}
          </Typography>
        </Box>
        <Button
          data-testid="multiple-upload-03D03B"
          variant="contained"
          color="secondary"
          component="label"
        >
          Select file
          <input
            data-testid="multiple-upload-758E91"
            {...rest}
            ref={ref}
            type="file"
            hidden
            multiple
            onChange={handleSelect}
          />
        </Button>
      </Styled.Upload>

      <Styled.Files>
        {files.map(({ file, isLoading, isDeleted, id }) => (
          <Styled.File key={id}>
            <Box display="flex" alignItems="center" gap={1}>
              {isLoading && <CircularProgress />}
              {!isLoading && isDeleted && (
                <Box>
                  <Typography>
                    You deleted file.{' '}
                    <Button
                      data-testid="multiple-upload-94B5D8"
                      onClick={handleUndo(id)}
                    >
                      Undo
                    </Button>
                  </Typography>
                </Box>
              )}
              {!isLoading && !isDeleted && (
                <>
                  <FileMiniActive />
                  <Typography
                    component="a"
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    variant="body2"
                    color={cyan[500]}
                    rel="noreferrer"
                  >
                    {file.name}
                  </Typography>
                  <Typography variant="body2" className="secondary-variant">
                    ({file.size}) Kb
                  </Typography>
                  <IconButton aria-label="delete" onClick={handleDelete(id)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </Box>
          </Styled.File>
        ))}
      </Styled.Files>
    </Styled.Root>
  )
})

export default MultipleUpload
