import { Api } from '@api/Api'
import {
  ContractDocument,
  ContractDocumentDto,
  ContractDocumentHandlersParams,
  ContractDocumentHandlersResponse,
  ContractDocumentParams,
  ContractDocuments,
  ContractUpdateDocumentDto,
  uploadDeclinedDocumentHandlersDto,
} from '@remoteam-front/types/v2'
import { de } from 'date-fns/locale'

import { generateService } from '../utils'

export const updateContractDocuments = async (
  id: number,
  { name }: ContractUpdateDocumentDto
) => {
  const fd = new FormData()
  fd.append('name', name)

  return Api.put<void>(`/contract-documents/${id}/update/`, fd)
}

export const deleteContractDocuments = async (id: number) => {
  const { data } = await Api.delete<void>(`contract-documents/${id}/delete/`)

  return data
}

export const createContractDocuments = async ({
  file,
  contract,
  name,
}: ContractDocumentDto) => {
  const fd = new FormData()
  fd.append('file', file)
  fd.append('name', name)
  fd.append('contract', contract.toString())

  return Api.post<void>(`/contract-documents/create/`, fd)
}

export const deleteDocumentsById = async ({
  id,
  contract: contractId,
}: Pick<ContractDocument, 'id' | 'contract'>) => {
  return Api.delete<void>(
    `contract-documents/${id}/delete/?contract_id=${contractId}`
  )
}

export const getContractDocuments = generateService(
  async (params: ContractDocumentParams) => {
    const { page = 1, limit = 10 } = params
    const offset = (page - 1) * limit
    const { data } = await Api.get<ContractDocuments>(`contract-documents/`, {
      params: {
        ...params,
        offset,
        limit,
      },
    })

    return data
  }
)

export const getContractDocumentHandlers = generateService(
  async (params: ContractDocumentHandlersParams) => {
    const { page, limit } = params
    const offset = (page - 1) * limit
    const { data } = await Api.get<ContractDocumentHandlersResponse>(
      `contract-documents-handlers/`,
      {
        params: {
          ...params,
          offset,
        },
      }
    )

    return data
  }
)

export const uploadDeclinedDocumentHandlers = async ({
  declined_id,
  files,
  progressCallBack,
}: uploadDeclinedDocumentHandlersDto) => {
  const fd = new FormData()
  files.forEach((file) => fd.append('files', file, file.name))
  const { data } = await Api.put<{ file_uuid: string }>(
    `/contract-documents-handlers/${declined_id}/upload/`,
    fd
    // {
    //   onUploadProgress: (progressEvent) => {
    //     const progress = parseInt(
    //       String(
    //         Math.round((progressEvent.loaded * 100) / progressEvent?.total)
    //       ),
    //       10
    //     )
    //     if (progressCallBack) progressCallBack(progress)
    //   },
    // }
  )

  return data
}

/**
 * Approve the contract document handler with the given id
 * @param id The id of the contract document handler to be approved
 * @returns A Promise that resolves to an empty object
 */
export const approveContractDocumentsHandlers = async (id: number) => {
  const { data } = await Api.put<void>(
    `/contract-documents-handlers/${id}/approve/`
  )

  return data
}

/**
 * Reject the contract document handler with the given id
 * @param id The id of the contract document handler to be rejected
 * @returns A Promise that resolves to an empty object
 */
export const rejectContractDocumentsHandlers = async (values: {
  id: number
  decline_reason: string
}) => {
  const { id, decline_reason } = values

  const { data } = await Api.put<void>(
    `/contract-documents-handlers/${id}/decline/`,
    {
      decline_reason,
    }
  )

  return data
}

/**
 * Delete the selected attachment from the contract document handler with the given id
 * @param id The id of the contract document handler
 * @param attachmentUuid The uuid of the attachment to be deleted
 * @returns A Promise that resolves to an empty object
 */
export const deleteSelectedAttachment = async (values: {
  id: number
  attachmentUuid: string
}) => {
  const { id, attachmentUuid } = values

  const { data } = await Api.delete<void>(
    `contract-documents-handlers/${id}/attachments/${attachmentUuid}`
  )
  return data
}
