import './EmployeesExpense.scss'

import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import { useRouteQuery } from '@core/hooks/useRouteQuery'
import { edit, fileIcon } from '@core/icons/icons'
import { formatDate } from '@core/utils'
import {
  categoryTypes,
  UpdateExpenseModal,
} from '@pages/employeesExpense/UpdateExpenseModal'
import { EventSides } from '@pages/time-off/enums'
import { cancelExpense, updateExpense } from '@services/expense.service'
import classNames from 'classnames'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const Styled = styled.div`
  background: #f9f9f9;
  border-radius: 0 0 8px 8px;
  padding: 24px;
`

const StyledComment = styled.div`
  word-break: break-all;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 8px;
`

const StyledTable = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`
export default ({ expense, refetch }) => {
  const { successAlert } = useToast()
  const history = useHistory()
  const routeQuery = useRouteQuery()
  const editTimOffModalState = useBoolean(
    Boolean(routeQuery.get('openEditModal'))
  )
  const confirmCancelExpense = useBoolean(false)
  const cancelExpenseRequest = useMutation({
    mutationFn: (payload) => cancelExpense(payload),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
    },
  })

  const handleModalOpen = () => {
    editTimOffModalState.setTrue()
  }
  const handleModalClose = () => {
    routeQuery.delete('openEditModal')
    history.replace({
      search: routeQuery.toString(),
    })
    editTimOffModalState.setFalse()
  }
  const editExpenseRequest = useMutation({
    mutationFn: (payload) => updateExpense(payload),
    onSuccess: () => {
      handleModalClose()
      refetch()
      successAlert('Successfully updated!')
    },
  })
  const onCancelExpense = () => {
    cancelExpenseRequest.mutate({
      id: expense.id,
      data: {
        event_side: 'PARTNER',
        status: 'CANCELED',
      },
    })
  }
  const onEditExpense = (body) => {
    const data = {
      id: expense.id,
      data: {
        ...body,
      },
    }
    editExpenseRequest.mutate(data)
  }

  const checkNegativeStatus = (status) => {
    return (
      status !== 'CANCELLED' &&
      status !== 'APPROVED_BY_COMPANY' &&
      status !== 'PAID' &&
      status !== 'AWAITING_PAYMENT' &&
      status !== 'REJECTED'
    )
  }
  const findRejectStatus = useMemo(
    () => expense.events.filter((evt) => evt.status === 'REJECTED')[0],
    [expense]
  )
  return (
    <Styled className="expenseEvent">
      <div className="row justify-content-center">
        {(expense.state === 'REJECTED' || expense.state === 'PENDING') && (
          <div className="col-3 content-body mr-1">
            <Status status={expense.state} />
            <div className="d-flex justify-content-between mt-2">
              <Typography as="span" className="text_medium__14">
                {expense.state === 'PENDING'
                  ? expense.created_by.full_name
                  : findRejectStatus.action_user?.full_name}
              </Typography>
              <Typography
                as="span"
                className="text_regular-normal__14 color_text_300"
              >
                {expense.state === 'PENDING'
                  ? EventSides[expense.creator_role]
                  : EventSides[findRejectStatus.event_side]}
              </Typography>
            </div>
            {expense.state === 'REJECTED' ? (
              <Typography as="span" className="text_regular-normal__14 mt-2">
                {findRejectStatus.reject_reason}
              </Typography>
            ) : (
              <Typography as="span" className="text_regular-normal__14 mt-2">
                The expense request has been sent for Client’s review
              </Typography>
            )}
          </div>
        )}
        <div className="col-5 content-body ml-1">
          <div className="d-flex justify-content-end mb-2">
            {checkNegativeStatus(expense.state) &&
              expense.creator_role === 'PARTNER' && (
                <Button
                  data-testid="ExpenseEvent-402E43"
                  onClick={handleModalOpen}
                  priority="secondary"
                  style={{ height: '32px' }}
                  size="small"
                  className="px-2 pl-0"
                >
                  <Icon fill="none" icon={edit} />
                  <Typography className={classNames('text_light__12', 'ml-2')}>
                    Edit
                  </Typography>
                </Button>
              )}
          </div>

          <StyledTable>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Expense name
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.5px' }}
                >
                  {expense.expense_name}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Company
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.5px' }}
                >
                  {expense.contract?.company_name}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Amount
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {expense.currency.short_code || expense.currency.sign}{' '}
                  {expense.amount}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Expense date
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {moment(expense.expense_date).format('DD MMM YYYY')}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Category
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {
                    categoryTypes.filter(
                      (type) => type.value === expense.category
                    )[0]?.label
                  }
                </Typography>
              </td>
            </tr>
            <tr>
              <th style={{ verticalAlign: 'top' }}>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Receipt
                  {expense.expense_receipts &&
                  expense.expense_receipts.length > 0
                    ? 's'
                    : ''}
                </Typography>
              </th>
              <td>
                <div className="d-flex flex-column align-items-end">
                  {expense.receipt ? (
                    <a
                      data-testid="ExpenseEvent-FF91C9"
                      target="_blank"
                      className="text_regular-normal__14 color_black d-flex align-items-center mb-1"
                      style={{ textDecoration: 'none' }}
                      href={expense.receipt}
                      rel="noreferrer"
                    >
                      <Icon
                        stroke="black"
                        className="mr-2"
                        fill="white"
                        icon={fileIcon}
                        style={{ cursor: 'pointer' }}
                      />
                      {expense.filename}
                    </a>
                  ) : (
                    <Typography
                      className="text_regular-normal__14"
                      style={{ letterSpacing: '0.4px' }}
                    >
                      -
                    </Typography>
                  )}

                  {expense.expense_receipts &&
                    expense.expense_receipts.map((receipt) => (
                      <a
                        key={receipt.filename}
                        target="_blank"
                        className="text_regular-normal__14 color_black d-flex align-items-center mb-1"
                        style={{ textDecoration: 'none' }}
                        href={receipt.file}
                        rel="noreferrer"
                      >
                        <Icon
                          stroke="black"
                          className="mr-2"
                          fill="white"
                          icon={fileIcon}
                          style={{ cursor: 'pointer' }}
                        />
                        {receipt.filename}
                      </a>
                    ))}
                </div>
              </td>
            </tr>
            {/* Approved date */}
            {expense.approved_date && (
              <tr>
                <th>
                  <Typography
                    className="text_regular-normal__14 ml-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    Approved date
                  </Typography>
                </th>
                <td align="right">
                  <Typography
                    className="text_regular-normal__14 mr-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    {formatDate(expense.approved_date)}
                  </Typography>
                </td>
              </tr>
            )}

            {expense.events.find((e) => e.status === 'AWAITING_PAYMENT') && (
              <tr>
                <th>
                  <Typography
                    className="text_regular-normal__14 ml-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    Approved by
                  </Typography>
                </th>
                <td align="right">
                  <Typography
                    className="text_regular-normal__14 mr-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    {
                      expense.events.find(
                        (e) => e.status === 'AWAITING_PAYMENT'
                      )?.action_user?.full_name
                    }
                  </Typography>
                </td>
              </tr>
            )}
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Payout date
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {expense?.payout_date
                    ? moment(expense.payout_date).format('MMMM')
                    : '-'}
                </Typography>
              </td>
            </tr>
          </StyledTable>
          {expense.description && (
            <div className="mt-2">
              <Typography
                className="text_regular-normal__14 ml-2 mb-2"
                style={{ letterSpacing: '0.4px' }}
              >
                Description
              </Typography>
              <StyledComment>{expense.description}</StyledComment>
            </div>
          )}
          {checkNegativeStatus(expense.state) &&
            expense.creator_role === 'PARTNER' && (
              <Button
                data-testid="ExpenseEvent-76DC82"
                className="mt-2 w-100"
                size="small"
                onClick={confirmCancelExpense.setTrue}
                priority="danger_outlined"
              >
                Cancel request
              </Button>
            )}
        </div>
      </div>
      {editTimOffModalState.value && (
        <UpdateExpenseModal
          title="Submit expense"
          onClose={handleModalClose}
          expense={expense}
          isEdit
          loading={editExpenseRequest.isLoading}
          onSubmit={onEditExpense}
        />
      )}
      {confirmCancelExpense.value && (
        <Confirm
          title="Cancel expense request?"
          yesButtonProps={{
            text: 'Cancel Forever',
          }}
          cancelButtonProps={{
            text: 'Close',
          }}
          isLoading={cancelExpenseRequest.isLoading}
          onCancel={confirmCancelExpense.setFalse}
          onYes={onCancelExpense}
        >
          <Typography>
            This action will cancel the request forever and you will not be able
            to edit it anymore. Employee and Client will be both notified if
            cancelled. The request will be stored in History tab.
          </Typography>
        </Confirm>
      )}
    </Styled>
  )
}
