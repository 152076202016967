import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { attachFile, deleteIcon } from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'

import styles from './upload.module.scss'

const Label = ({ label, addText, id, isRequired }) => {
  return (
    <>
      {label && (
        <label
          className={classNames([
            'text_medium__14',
            !addText && 'remo-form-label',
          ])}
          htmlFor={id || ''}
        >
          {label} {isRequired && <span className="text-danger ml-1">*</span>}
        </label>
      )}
      {addText && (
        <Typography
          className="text_light__12 color_text_300 mt-1"
          style={{ marginBottom: 12 }}
        >
          {addText}
        </Typography>
      )}
    </>
  )
}

export default React.forwardRef(
  (
    {
      id,
      onChange,
      onRemove,
      label,
      addText,
      progress,
      isLoading,
      isRequired,
      isSuccess,
      accept = '',
      multiple = false,
      maxFiles = 5,
      files = [],
      name,
    },
    ref
  ) => {
    const [localFiles, setLocalFiles] = React.useState(files)

    const handleRemove = (index) => {
      const updatedFiles = [...localFiles]
      updatedFiles.splice(index, 1)
      setLocalFiles(updatedFiles)
      if (onRemove) onRemove(index)
    }

    const handleFileInput = (e) => {
      const selectedFiles = e.target.files
      if (selectedFiles.length > 0) {
        if (multiple) {
          const newFiles = [...localFiles]
          Array.from(selectedFiles).forEach((file) => {
            if (newFiles.length < maxFiles) {
              newFiles.push(file)
            }
          })
          setLocalFiles(newFiles)
          if (selectedFiles[0]) {
            onChange(selectedFiles[0], newFiles)
          }
        } else {
          const selectedFile = selectedFiles[0]
          setLocalFiles([selectedFile])
          onChange(selectedFile)
        }
      }
    }

    // Render file list
    const renderFileList = () => {
      if (!localFiles || localFiles.length === 0) return null

      return (
        <div className={styles.fileList}>
          {localFiles.map((file, index) => (
            <div
              key={file.name || `file-${Date.now()}-${Math.random()}`}
              className={styles.fileItem}
            >
              <span className={styles.fileName} title={file.name}>
                {file.name}
              </span>
              <button
                type="button"
                className={styles.deleteButton}
                onClick={() => handleRemove(index)}
                aria-label="Delete file"
              >
                <Icon icon={deleteIcon} />
                Delete
              </button>
            </div>
          ))}
        </div>
      )
    }

    // Render add more files button
    const renderAddMoreFiles = () => {
      if (!multiple || localFiles.length >= maxFiles) return null

      return (
        <div className={styles.addMoreFilesContainer}>
          <label htmlFor={`file-input_${id}`} className={styles.addMoreLabel}>
            <Icon icon={attachFile} className={styles.attachIcon} />
            Add more files ({localFiles.length}/{maxFiles})
          </label>
        </div>
      )
    }

    if (isLoading) {
      return (
        <>
          <Label label={label} addText={addText} isRequired={isRequired} />
          <div className={styles.dropzone}>
            <ProgressBar
              now={progress}
              label={<Typography>{progress}%</Typography>}
            />
          </div>
          {renderFileList()}
        </>
      )
    }

    return (
      <>
        <Label label={label} addText={addText} isRequired={isRequired} />
        {(!isSuccess || localFiles.length === 0) && (
          <label htmlFor={`file-input_${id}`} className={styles.label}>
            <Icon icon={attachFile} className={styles.attachIcon} />
            {multiple
              ? `Select files (${localFiles.length}/${maxFiles})`
              : 'Select files'}
            <input
              data-testid="UploadButton-index-0D2BC8"
              ref={ref}
              type="file"
              name={name}
              id={`file-input_${id}`}
              className={styles.input}
              accept={accept}
              onChange={handleFileInput}
              multiple={multiple}
            />
          </label>
        )}
        {isSuccess && renderFileList()}
        {isSuccess && renderAddMoreFiles()}
      </>
    )
  }
)
