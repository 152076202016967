import { Box, Button, Chip, IconButton, Link, Typography } from '@mui/material'
import { theme } from '@remoteam-front/ui'
import {
  CheckmarkFilledIcon,
  DeleteIcon,
  DismissFilledIcon,
  InfoOutline,
  UploadFilledIcon,
} from '@remoteam-front/ui/icons'
import { mapEnum } from '@remoteam-front/utils'
import {
  approveDocument,
  declineDocument,
  getOnboardingDocuments,
} from '@services/app.service'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Styled } from './onboarding-tracker-required-documents.styles'
import { RequiredDocumentAttachmentDelete } from './required-document-attachment-delete/required-document-attachment-delete.component'
import { RejectDialog } from './required-document-reject-dialog/required-document-reject-dialog.component'
import { RequiredDocumentUpload } from './required-document-upload/required-document-upload.component'

const renderApprovedRejectBy = (group) =>
  group === 'admin' ? (
    <Typography variant="textSmall1" color={theme.palette.secondary[300]}>
      RemoFirst
    </Typography>
  ) : null

export const OnboardingTrackerRequiredDocuments = ({
  employeeDocs,
  employee,
}) => {
  const [docToDecline, setDocToDecline] = useState(null)

  const [docToUpload, setDocToUpload] = useState(null)
  const [attachmentToDelete, setAttachmentToDelete] = useState(null)
  const [docToDelete, setDocToDelete] = useState(null)

  const queryClient = useQueryClient()

  const approveMutation = useMutation({
    mutationFn: approveDocument,
    onSuccess: () => {
      queryClient.refetchQueries(getOnboardingDocuments.key)
    },
  })
  const rejectMutation = useMutation({
    mutationFn: (decline_reason) =>
      declineDocument(docToDecline.id, { decline_reason }),
    onSuccess: () => {
      queryClient.refetchQueries(getOnboardingDocuments.key)
      setDocToDecline(null)
    },
  })

  const handleApprove = (employeeDocId) => () => {
    approveMutation.mutate(employeeDocId)
  }

  const renderButtons = (reqDoc, child) => {
    if (reqDoc.state === 'approved') {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          {renderApprovedRejectBy(reqDoc.approve_group)}
          <Chip color="success" size="small" label="Approved" />
        </Box>
      )
    }
    if (reqDoc.state === 'declined') {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton
            size="small"
            variant="contained"
            disableRipple
            disableTouchRipple
            disabled={
              employee.onboarding_stage?.substage.fte_logged_in === 'pending' ||
              employee.onboarding_stage?.substage.fte_infodoc_submitted ===
                'pending'
            }
            onClick={() => setDocToUpload(reqDoc)}
          >
            <UploadFilledIcon />
          </IconButton>
          {renderApprovedRejectBy(reqDoc.decline_group)}
          <Chip color="error" size="small" label="Rejected" />
        </Box>
      )
    }
    if (
      reqDoc.state === 'not_uploaded' ||
      reqDoc.state === 'unable_to_provide'
    ) {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton
            size="small"
            variant="contained"
            disableRipple
            disableTouchRipple
            disabled={
              employee.onboarding_stage?.substage.fte_logged_in === 'pending' ||
              employee.onboarding_stage?.substage.fte_infodoc_submitted ===
                'pending'
            }
            onClick={() => setDocToUpload(reqDoc)}
          >
            <UploadFilledIcon />
          </IconButton>
          <Button
            data-testid="onboarding-tracker-required-documents.component-A19F56"
            variant="contained"
            size="small"
            startIcon={<CheckmarkFilledIcon />}
            disabled
          >
            Approve
          </Button>
          <Button
            data-testid="onboarding-tracker-required-documents.component-DDCCDF"
            variant="contained"
            color="error"
            size="small"
            startIcon={<DismissFilledIcon />}
            disabled
          >
            Reject
          </Button>
        </Box>
      )
    }
    return child
  }

  const renderContent = (employeeDoc, onEditDecline) => {
    if (employeeDoc.state === 'not_uploaded') {
      return (
        <Styled.Doc>
          <Typography variant="body1">
            Documents uploaded by the employee will appear here.
          </Typography>
        </Styled.Doc>
      )
    }

    if (employeeDoc.state === 'unable_to_provide') {
      return (
        <Styled.Doc>
          <Chip
            color="warning"
            label="The employee declares that they cannot provide the document."
            icon={<InfoOutline />}
          />
        </Styled.Doc>
      )
    }

    if (employeeDoc.state === 'declined') {
      return (
        <>
          <Styled.Doc>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography component="span">
                Rejection reason:{' '}
                <Typography color="#6D6D6C" component="span">
                  {employeeDoc.decline_reason}
                </Typography>{' '}
              </Typography>
              <Button size="small" onClick={onEditDecline}>
                Edit
              </Button>
            </Box>
          </Styled.Doc>

          <Styled.Doc>
            {employeeDoc.attachments.map((attachment) => (
              <Box
                key={attachment.file_uuid}
                display="flex"
                gap={1}
                alignItems="center"
              >
                <Link
                  data-testid="onboarding-tracker-required-documents.component-DF85BB"
                  color="primary"
                  target="_blank"
                  href={attachment.file}
                  rel="noreferrer"
                  underline="hover"
                >
                  {attachment.filename}
                </Link>

                <IconButton
                  size="small"
                  onClick={() => {
                    setAttachmentToDelete(attachment)
                    setDocToDelete(employeeDoc)
                  }}
                >
                  <DeleteIcon sx={{ marginTop: 0.7 }} />
                </IconButton>
              </Box>
            ))}
          </Styled.Doc>
        </>
      )
    }

    return (
      <Styled.Doc>
        {employeeDoc.attachments.map((attachment) => (
          <Link
            key={attachment.file_uuid}
            data-testid="onboarding-tracker-required-documents.component-DF85BB"
            color="primary"
            target="_blank"
            href={attachment.file}
            rel="noreferrer"
            underline="hover"
          >
            {attachment.filename}
          </Link>
        ))}
      </Styled.Doc>
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} width="50%">
        {employeeDocs.map((doc) => (
          <Styled.Card key={doc.id}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="textLarge2">
                  {doc.employee_document.name}
                </Typography>
                {!doc.is_required && '(Optional)'}
              </Box>

              {renderButtons(
                doc,
                <Box display="flex" gap={1}>
                  <IconButton
                    size="small"
                    variant="contained"
                    disableRipple
                    disableTouchRipple
                    disabled
                  >
                    <UploadFilledIcon />
                  </IconButton>
                  <Button
                    data-testid="onboarding-tracker-required-documents.component-53ABF0"
                    variant="contained"
                    size="small"
                    startIcon={
                      !approveMutation.isLoading && <CheckmarkFilledIcon />
                    }
                    disabled={approveMutation.isLoading}
                    onClick={handleApprove(doc.id)}
                  >
                    Approve
                  </Button>
                  <Button
                    data-testid="onboarding-tracker-required-documents.component-46F18F"
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<DismissFilledIcon />}
                    onClick={() => setDocToDecline(doc)}
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={1} mt="10px">
              {renderContent(doc, () => setDocToDecline(doc))}
            </Box>
          </Styled.Card>
        ))}
      </Box>
      {docToDecline && (
        <RejectDialog
          reqDoc={docToDecline}
          isLoading={rejectMutation.isLoading}
          onClose={() => setDocToDecline(null)}
          onReject={rejectMutation.mutate}
        />
      )}
      {docToUpload ? (
        <RequiredDocumentUpload
          reqDocument={docToUpload}
          onRefetch={() =>
            queryClient.refetchQueries(getOnboardingDocuments.key)
          }
          onClose={() => setDocToUpload(null)}
        />
      ) : null}
      {attachmentToDelete ? (
        <RequiredDocumentAttachmentDelete
          reqDoc={docToDelete}
          attachment={attachmentToDelete}
          onClose={() => setAttachmentToDelete(null)}
          onRefetch={() =>
            queryClient.refetchQueries(getOnboardingDocuments.key)
          }
        />
      ) : null}
    </>
  )
}
