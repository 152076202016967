import Checkbox from '@atoms/Checkbox/Checkbox'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import {
  ADDITIONAL_PAYMENT_TYPE_KEY,
  calculateLastPayoutMonth,
  frequencyTypes,
  getFrequencyMessage,
  monthOptions,
  paymentTypes,
} from '@pages/additional-payments/utils'
import { fetchCompanies } from '@pages/customers/CustomersPage.service'
import { fetchContractorsByCompany } from '@pages/employees/EmployeesPage.service'
import { employeeTableParse } from '@pages/employees/parse'
import {
  calculateAddPmtEmployerCostTaxes,
  getCostCalculatorList,
} from '@services/cost-calculation.service'
import {
  getPayrollListCompany,
  postCalculateLastDay,
} from '@services/payroll.service'
import { isNumber } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useDebounce } from 'usehooks-ts'

const formId = 'payment_create_form'

export default ({
  isLoading,
  onHide,
  onCreate,
  isSuccess,
  currency,
  isEdit,
  selectedItem,
}) => {
  const { currencies, profile, userProfile } = useApp()
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))

  const [contracts, setContracts] = useState([])
  const [companies, setCompanies] = useState([])
  const [minDate, setMinDate] = useState(currentDate)
  const [apTypeKeys, setApTypeKeys] = useState()
  const [showCurrentMonth, setShowCurrentMonth] = useState(true)
  const [isPayoutDateLocked, setIsPayoutDateLocked] = useState(true)

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      company: selectedItem ? selectedItem?.company_data?.id : null,
      contract: selectedItem ? selectedItem.contract_data.id : null,
      ap_type_id: selectedItem?.ap_type || undefined,
      amount: selectedItem ? selectedItem.amount : '',
      name: selectedItem ? selectedItem.title : '',
      description: selectedItem ? selectedItem.description : '',
      currency: selectedItem ? selectedItem.currency.id : currency?.id,
      tax: selectedItem ? selectedItem.tax : '',
      frequency: selectedItem
        ? {
            value: selectedItem.recurrence_frequency,
            label: frequencyTypes.find(
              (f) => f.value === selectedItem.recurrence_frequency
            )?.label,
          }
        : null,
      firstPayoutMonth: selectedItem
        ? {
            value: selectedItem.recurrence_starting_month,
            label: moment(selectedItem.recurrence_starting_month).format(
              'MMMM YYYY'
            ),
          }
        : null,
      hasEndOccurrences: selectedItem ? !!selectedItem.occurrences : false,
      endAfterOccurrences: selectedItem?.occurrences || '',
    },
  })

  const companyWatch = watch('company')
  const isRecurrentWatch = watch('is_recurrent')
  const frequencyWatch = watch('frequency')
  const firstPayoutMonthWatch = watch('firstPayoutMonth')
  const hasEndOccurrencesWatch = watch('hasEndOccurrences')
  const endAfterOccurrencesWatch = watch('endAfterOccurrences')
  const employee = watch('contract')
  const type = watch('ap_type_id')
  const amount = watch('amount')
  const tax = watch('tax')
  const total = +(amount || 0) + +(tax || 0)
  const debounceAmount = useDebounce(amount)

  const addPmtListMutation = useQuery(
    [getCostCalculatorList.key, employee?.countryId],
    {
      queryFn: () =>
        getCostCalculatorList.fetch({
          limit: 1000,
          offset: 0,
          country_id: employee?.countryId,
          ignore_region: true,
          usage_area: 'additional_payment',
          partner: {
            id: profile.partner_profile_id,
            name: profile.name,
            user_id: userProfile.id,
          },
        }),
      enabled: !!employee?.countryId,
      onSuccess: (res) => {
        setApTypeKeys(
          res?.results.reduce((acc, value) => {
            return { ...acc, [value.id]: value }
          }, {})
        )
      },
    }
  )
  const calcAPQuery = useQuery(
    [
      calculateAddPmtEmployerCostTaxes.key,
      debounceAmount,
      employee?.countryId,
      type,
    ],
    {
      enabled:
        isNumber(type?.value || type) && !!employee?.countryId && !isEdit,
      queryFn: () =>
        calculateAddPmtEmployerCostTaxes.fetch({
          additional_payment_amount: debounceAmount || 0,
          additional_payment_type_id: type?.value || type,
          country: employee?.countryId,
        }),
      onSuccess: (res) => {
        if (!isEdit) setValue('tax', res.tax_amount)
      },
    }
  )

  useEffect(() => {
    if (selectedItem?.contract_data?.id && contracts?.length && isEdit) {
      const contractValue = contracts.find(
        (c) => c.id === selectedItem.contract_data.id
      )
      if (contractValue) {
        setValue('contract', {
          value: contractValue.id,
          contractor:
            contractValue.contract_type === 'contractor' ? ' (Contractor)' : '',
          label: contractValue.full_name,
          countryId: contractValue.working_country?.id,
          residencyType: contractValue.residency_type,
        })
      }
    }
  }, [selectedItem?.contract_data?.id, contracts, isEdit])

  useEffect(() => {
    if (selectedItem?.sub_ap_type && apTypeKeys) {
      const ap_type_id = Object.values(apTypeKeys).find(
        (value) => value.name === selectedItem?.sub_ap_type
      )?.id
      if (ap_type_id) setValue('ap_type_id', ap_type_id)
    }
  }, [selectedItem?.sub_ap_type, apTypeKeys])

  const { isLoading: isLoadingPayroll } = useQuery(
    ['payrolls', companyWatch],
    () => {
      return getPayrollListCompany({
        state: 'pending',
        limit: 1000,
        company: companyWatch,
      })
    },
    {
      enabled: !!companyWatch,
      onSuccess: (data) => {
        // Unlock the payout date selection after we've determined the status
        if (data?.data?.length > 0) {
          const companyPayroll = data.data.find(
            (item) => item.company_id === companyWatch
          )
          if (
            companyPayroll &&
            companyPayroll.payrolls &&
            companyPayroll.payrolls.length > 0
          ) {
            const wasPartnerApproved =
              companyPayroll.payrolls[0].was_partner_approved
            setShowCurrentMonth(!wasPartnerApproved)
          } else {
            setShowCurrentMonth(true)
          }
        } else {
          setShowCurrentMonth(true)
        }
        // Unlock the payout date selection after processing
        setIsPayoutDateLocked(false)
      },
      onError: () => {
        // In case of error, default to showing current month and unlock selection
        setShowCurrentMonth(true)
        setIsPayoutDateLocked(false)
      },
    }
  )

  const { isLoading: isLoadingCompany } = useQuery(['companies'], {
    queryFn: () => fetchCompanies('active,onboarding', undefined, 1, 1000),
    onSuccess: ({ results }) => {
      setCompanies(results)
    },
  })

  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })

  const { isLoading: isLoadingContracts } = useQuery(
    ['contractors', companyWatch, isRecurrentWatch],
    {
      queryFn: () =>
        fetchContractorsByCompany(
          companyWatch.value ?? companyWatch,
          undefined,
          1,
          `&contract_type=${
            isRecurrentWatch === 'true' ? 'full_time_employee' : ''
          }&state=active`,
          1000
        ),
      onSuccess: ({ results }) => {
        setContracts(employeeTableParse(results))
        if (!selectedItem?.contract_data?.id) setValue('contract', null)
      },
      enabled: !!companyWatch,
    }
  )

  const submit = (formValues) => {
    const category =
      apTypeKeys &&
      apTypeKeys[formValues.ap_type_id?.value || formValues.ap_type_id]
    if (isEdit) {
      const body = {
        amount: Number(formValues.amount),
        description: formValues.description,
        name: formValues.name,
        tax: formValues.tax || undefined,
        ap_type: category
          ? ADDITIONAL_PAYMENT_TYPE_KEY[category.additional_payment_category]
          : formValues.ap_type_id?.value || formValues.ap_type_id,
        sub_ap_type: category ? category.name : undefined,
      }
      onCreate(body)
    } else {
      const body = {
        amount: Number(formValues.amount),
        description: formValues.description,
        name: formValues.name,
        contract: formValues?.contract.value || formValues?.contract,
        currency: formValues.currency.value || formValues.currency,
        is_recurrent: formValues.frequency?.value !== 'ONE_TIME',
        ap_type: category
          ? ADDITIONAL_PAYMENT_TYPE_KEY[category.additional_payment_category]
          : formValues.ap_type_id?.value || formValues.ap_type_id,
        sub_ap_type: category ? category.name : undefined,
        tax: calcAPQuery.data?.tax_amount || undefined,
        ...(formValues.tax && { tax: formValues.tax }),
      }

      if (formValues.frequency?.value !== 'ONE_TIME') {
        Object.assign(body, {
          recurrence_frequency: formValues.frequency.value,
          recurrence_starting_month: formValues.firstPayoutMonth.value,
          ...(formValues.hasEndOccurrences &&
            formValues.endAfterOccurrences && {
              recurrence_ending_month: moment(formValues.firstPayoutMonth.value)
                .clone()
                .add(
                  (formValues.endAfterOccurrences - 1) *
                    {
                      MONTHLY: 1,
                      EVERY_2_MONTHS: 2,
                      EVERY_3_MONTHS: 3,
                    }[formValues.frequency.value],
                  'months'
                )
                .format('YYYY-MM-DD'),
            }),
        })
      }

      onCreate(body)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      reset()
    }
  }, [isSuccess])

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.allowed_countries[0]?.id,
        days: profile.additional_payment_cut_off_day,
      })
    }
  }, [profile])

  useEffect(() => {
    if (
      postCalculateLastDayMutate?.data?.last_day &&
      currentDate.getTime() >=
        new Date(postCalculateLastDayMutate?.data?.last_day).getTime()
    ) {
      const nextDate = new Date()
      nextDate.setMonth(currentDate.getMonth() + 1)
      nextDate.setDate(1)
      setMinDate(nextDate)
    }
  }, [postCalculateLastDayMutate.data])

  const options = useMemo(() => {
    return addPmtListMutation.data?.results.length
      ? addPmtListMutation.data.results.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      : paymentTypes
  }, [addPmtListMutation.data])

  return (
    <ModalSide
      title={`${isEdit ? 'Edit' : 'Add'} additional payment`}
      onClose={onHide}
      onCancel={onHide}
      onOk={handleSubmit(submit)}
      okText="Save"
      cancelText="Close"
      okButtonProps={{
        type: 'submit',
        form: formId,
        size: 'small',
        disabled: isLoading,
        loading: isLoading,
        'data-testid': 'CreateModal-C431DF',
      }}
      cancelButtonProps={{
        priority: 'secondary',
        size: 'small',
        type: 'button',
        'data-testid': 'CreateModal-4EE017',
      }}
    >
      <form
        noValidate
        id={formId}
        className="flex flex-col"
        onSubmit={handleSubmit(submit)}
      >
        <div className="flex flex-col">
          <div style={{ marginBottom: '32px' }}>
            <Controller
              control={control}
              name="company"
              rules={{ required: 'Field is required' }}
              render={({ field }) => (
                <Select
                  data-testid="CreateModal-ACF57E"
                  {...field}
                  isDisabled={isEdit || isLoadingCompany}
                  isRequired
                  label={isLoadingCompany ? 'Loading...' : 'Select company'}
                  options={companies.map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))}
                />
              )}
            />
            {errors.company && (
              <Typography className="text_regular__14 color_red">
                {errors.company.message}
              </Typography>
            )}
          </div>
          <div style={{ marginBottom: '32px' }}>
            <Controller
              control={control}
              name="contract"
              rules={{ required: 'Field is required' }}
              render={({ field }) => (
                <Select
                  data-testid="CreateModal-230DF5"
                  {...field}
                  isDisabled={isEdit || !companyWatch || isLoadingContracts}
                  isRequired
                  label={isLoadingContracts ? 'Loading...' : 'Select employee'}
                  options={contracts
                    .filter(
                      (contract) => contract.contractor_wage_type === 'monthly'
                    )
                    .map((contract) => ({
                      value: contract.id,
                      contractor:
                        contract.contract_type === 'contractor'
                          ? ' (Contractor)'
                          : '',
                      label: contract.full_name,
                      countryId: contract.working_country?.id,
                      residencyType: contract.residency_type,
                    }))}
                  getOptionLabel={(option) =>
                    `${option.label}${option.contractor}`
                  }
                />
              )}
            />
          </div>
          <div style={{ marginBottom: '32px' }}>
            <Controller
              control={control}
              name="ap_type_id"
              rules={{ required: 'Field is required' }}
              render={({ field }) => (
                <Select
                  data-testid="CreateModal-C6D621"
                  {...field}
                  label="Payment type"
                  loading={addPmtListMutation.isLoading}
                  isDisabled={isEdit || addPmtListMutation.isLoading}
                  options={options}
                />
              )}
            />
            {errors.ap_type_id && (
              <Typography className="text_regular__14 color_red">
                {errors.ap_type_id.message}
              </Typography>
            )}
            {errors.contract && (
              <Typography className="text_regular__14 color_red">
                {errors.contract.message}
              </Typography>
            )}
          </div>
          <div style={{ marginBottom: '32px' }}>
            <Controller
              control={control}
              name="currency"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => (
                <Select
                  data-testid="CreateModal-D66008"
                  {...field}
                  label="Currency"
                  isDisabled={isEdit}
                  options={currencies.map((country) => ({
                    value: country.id,
                    label: country.name || country.short_code,
                  }))}
                />
              )}
            />
            {errors.currency && (
              <Typography className="text_regular__14 color_red">
                {errors.currency.message}
              </Typography>
            )}
          </div>
          <div style={{ marginBottom: '32px' }}>
            <Controller
              control={control}
              name="amount"
              rules={{
                required: 'Field is required',
                validate: {
                  minlength: (v) =>
                    /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) ||
                    'Only 2 digits allowed after decimal point',
                },
              }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  isRequired
                  label="Amount"
                  placeholder="Enter the adjustment amount"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {errors.amount && (
              <Typography className="text_regular__14 color_red">
                {errors.amount.message}
              </Typography>
            )}
          </div>
          <div style={{ marginBottom: '32px' }}>
            <Controller
              control={control}
              name="tax"
              rules={{ required: isEdit ? 'Field is required' : false }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  label="Employer tax & contributions"
                  placeholder="Enter the tax"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {errors.tax && (
              <Typography className="text_regular__14 color_red">
                {errors.tax.message}
              </Typography>
            )}
          </div>
          {(total && (
            <div style={{ marginBottom: '32px' }}>
              <Input disabled value={total} label="Total" placeholder="Tax" />
              <Typography style={{ fontSize: '12px', color: '#8A96A1' }}>
                The estimated total amount, including employer taxes and
                contributions
              </Typography>
            </div>
          )) ||
            ''}
          <div style={{ marginBottom: '32px' }}>
            <Controller
              control={control}
              name="frequency"
              rules={{ required: 'Field is required' }}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    label="Frequency"
                    isRequired
                    isDisabled={isEdit}
                    options={frequencyTypes}
                  />
                  {field.value && (
                    <Typography className="text-gray-500 mt-1">
                      {getFrequencyMessage(field.value)}
                    </Typography>
                  )}
                </>
              )}
            />
          </div>
          {!isEdit && frequencyWatch?.value !== 'ONE_TIME' && (
            <div style={{ marginBottom: '32px' }}>
              <Controller
                control={control}
                name="firstPayoutMonth"
                rules={{ required: 'Field is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="First expected payout month"
                    isRequired
                    isDisabled={
                      isEdit ||
                      isLoadingPayroll ||
                      isPayoutDateLocked ||
                      !companyWatch
                    }
                    isLoading={isLoadingPayroll || isPayoutDateLocked}
                    options={monthOptions(minDate, showCurrentMonth)}
                  />
                )}
              />
            </div>
          )}
          {!isEdit && frequencyWatch?.value !== 'ONE_TIME' && (
            <div style={{ marginBottom: '32px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    {...register('hasEndOccurrences')}
                    label="End after"
                  />
                  <Controller
                    control={control}
                    name="endAfterOccurrences"
                    rules={{
                      required: hasEndOccurrencesWatch
                        ? 'Number of occurrences is required'
                        : false,
                      min: {
                        value: 1,
                        message: 'Minimum 1 occurrence required',
                      },
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        id="endAfterOccurrences"
                        disabled={!watch('hasEndOccurrences')}
                        min="1"
                        style={{
                          width: '50px',
                          marginLeft: '.5rem',
                          marginRight: '.5rem',
                        }}
                      />
                    )}
                  />
                  <span>occurrences</span>
                </div>
                {errors.endAfterOccurrences && (
                  <Typography
                    className="text_regular__14 color_red"
                    style={{
                      display: 'block',
                      marginLeft: '1.75rem',
                    }}
                  >
                    {errors.endAfterOccurrences.message}
                  </Typography>
                )}
                <span
                  style={{
                    display: 'block',
                    marginLeft: '1.75rem',
                    color: '#ADADAD',
                    fontSize: '12px',
                  }}
                >
                  {hasEndOccurrencesWatch &&
                  endAfterOccurrencesWatch &&
                  frequencyWatch &&
                  firstPayoutMonthWatch
                    ? `Last payout will be in ${calculateLastPayoutMonth(
                        firstPayoutMonthWatch,
                        frequencyWatch,
                        Number(endAfterOccurrencesWatch)
                      )}`
                    : 'Payment will be indefinite.'}
                </span>
              </div>
            </div>
          )}
          <div style={{ marginBottom: '32px' }}>
            <Input
              data-testid="CreateModal-93A4B9"
              {...register('name', {
                required: 'Field is required',
                maxLength: {
                  value: 50,
                  message: 'Title cannot exceed 50 characters',
                },
              })}
              isRequired
              label="Payment name"
              placeholder="Title"
            />
            {errors.name && (
              <Typography className="text_regular__14 color_red">
                {errors.name.message}
              </Typography>
            )}
          </div>
          <div style={{ marginBottom: '32px' }}>
            <TextArea
              placeholder="Description..."
              {...register('description')}
            />
            {errors.description && (
              <Typography className="text_regular__14 color_red">
                {errors.description.message}
              </Typography>
            )}
          </div>
        </div>
      </form>
    </ModalSide>
  )
}
