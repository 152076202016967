/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { SplitAmount } from '@core/utils'
import { fetchCompanies } from '@pages/customers/CustomersPage.service'
import { fetchContractorsByCompany } from '@pages/employees/EmployeesPage.service'
import { employeeTableParse } from '@pages/employees/parse'
import { fetchCurrencies } from '@services/app.service'
import { DotWave } from '@uiball/loaders'
import { omit } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { CreateExpensePanel } from './create-expense-accordion'
import { CreateExpenseForm } from './create-expense-form.component'

const getAmount = (amount, label) => {
  if (!amount || !label) {
    return ''
  }

  return `(${SplitAmount(amount)} ${label})`
}

const firstFormId = v4()

const categoryTypes = [
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'COWORKING_SPACE', label: 'Coworking space' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'FOOD', label: 'Food' },
  { value: 'OFFICE_SUPPLIES', label: 'Office supplies' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'OTHER', label: 'Other' },
]

export const CreateExpenseModal = ({ onClose, onSubmit, loading }) => {
  const [contracts, setContracts] = useState([])
  const [expandedItemId, setExpandedItemId] = useState(firstFormId)
  const closeConfirm = useBoolean(false)

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      company: '',
      contract: '',
      category: '',
      expense_name: '',
      description: '',
      fields: {
        [firstFormId]: {
          expense_date: '',
          amount: '',
          currency: '',
          receipt: '',
        },
      },
    },
  })

  const fields = form.watch('fields')
  const company = form.watch('company')

  const companiesQuery = useQuery(['companies'], {
    queryFn: () => fetchCompanies('active,onboarding', undefined, 1, 1000),
  })

  useEffect(() => {
    if (companiesQuery.data && companiesQuery.data.results?.length === 1) {
      form.setValue('company', companiesQuery.data.results[0].id)
    }
  }, [companiesQuery.data, form])

  const contractorsQuery = useQuery(['contractors', company], {
    keepPreviousData: true,
    queryFn: () =>
      fetchContractorsByCompany(
        company.value ?? company,
        undefined,
        1,
        '&state=active',
        1000
      ),
    onSuccess: ({ results }) => {
      setContracts(employeeTableParse(results))
    },
  })

  const currenciesQuery = useQuery('fetchCurrenciesExpense', {
    queryFn: () =>
      fetchCurrencies({ limit: 1000, offset: 0, ordering: 'short_code' }),
  })

  const handleSubmit = () => {
    form.trigger()

    const { category, contract, expense_name, description } = form.getValues()

    const body = {
      expense_managements: Object.values(fields).map(
        ({ expense_date, currency, amount, receipt, receipts = [] }) => ({
          contract: contract.value,
          category: category.value,
          expense_name,
          description,
          expense_date: moment(expense_date).format('YYYY-MM-DD'),
          amount,
          currency: currency.value,
          receipt,
          receipts,
        })
      ),
    }
    onSubmit(body)
  }

  const handleAddNewForm = () => {
    const newFormId = v4()

    form.setValue('fields', {
      ...fields,
      [newFormId]: {
        expense_date: '',
        amount: '',
        currency: '',
        receipt: '',
      },
    })
    setExpandedItemId(newFormId)
  }

  const handleRemove = (formId) => {
    const isLastReceipt = Object.keys(fields).length === 1
    if (isLastReceipt) {
      return
    }

    form.setValue('fields', omit(fields, [formId]))
  }

  const fieldsKeysList = Object.keys(fields)

  if (
    currenciesQuery.isLoading ||
    contractorsQuery.isLoading ||
    companiesQuery.isLoading
  ) {
    return (
      <ModalSide
        title="Submit expense request(s)"
        footer={false}
        className="h-100"
        onClose={onClose}
      >
        <div className="d-flex justify-content-center align-items-center h-100">
          <DotWave />
        </div>
      </ModalSide>
    )
  }

  return (
    <FormProvider {...form}>
      <ModalSide
        title="Submit expense request(s)"
        okButtonProps={{
          loading,
        }}
        okText="Create request(s)"
        onClose={form.formState.isDirty ? closeConfirm.setTrue : onClose}
        onOk={handleSubmit}
      >
        <div className="d-flex flex-column gap-2">
          <Controller
            control={form.control}
            name="company"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="create-expense-modal.component-7EA7AD"
                  {...field}
                  isDisabled={companiesQuery.isLoading}
                  isRequired
                  addText="You can choose only active companies from the list"
                  label={
                    companiesQuery.isLoading ? 'Loading...' : 'Select company'
                  }
                  options={companiesQuery.data.results.map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))}
                />
              )
            }}
          />
          {form.formState.errors?.company && (
            <Typography className="text_regular__14 color_red">
              {form.formState.errors.company.message}
            </Typography>
          )}
          <div className="w-100">
            <Controller
              control={form.control}
              name="contract"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="create-expense-modal.component-34285B"
                    {...field}
                    isDisabled={!company || contractorsQuery.isFetching}
                    isRequired
                    addText="You can choose only active workers from the list"
                    label={
                      contractorsQuery.isFetching
                        ? 'Loading...'
                        : 'Select worker'
                    }
                    options={contracts.map((contract) => ({
                      value: contract.id,
                      label: contract.full_name,
                    }))}
                  />
                )
              }}
            />
            {form.formState.errors?.contract && (
              <Typography className="text_regular__14 color_red">
                {form.formState.errors.contract.message}
              </Typography>
            )}
          </div>
          <div className="w-100">
            <Controller
              control={form.control}
              name="category"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="create-expense-modal.component-871B7C"
                    isRequired
                    {...field}
                    label="Category"
                    options={categoryTypes}
                  />
                )
              }}
            />
            {form.formState.errors?.category && (
              <Typography className="text_regular__14 color_red">
                {form.formState.errors.category.message}
              </Typography>
            )}
          </div>
          <div>
            <Input
              data-testid="create-expense-modal.component-77E5AA"
              {...form.register(`expense_name`, {
                required: 'Field is required',
              })}
              isRequired
              type="text"
              label="Expense name"
              placeholder="Enter expense name"
            />
            {form.formState.errors?.expense_name && (
              <Typography className="text_regular__14 color_red">
                {form.formState.errors.expense_name.message}
              </Typography>
            )}
          </div>
          <TextArea
            isRequired
            label="Description"
            placeholder="Provide more details"
            {...form.register(`description`, {
              required: 'Field is required',
            })}
          />
          {form.formState.errors?.description && (
            <Typography className="text_regular__14 color_red">
              {form.formState.errors.description.message}
            </Typography>
          )}
        </div>

        {fieldsKeysList.map((formId, index) => (
          <CreateExpensePanel
            key={formId}
            isError={!!form.formState.errors.fields?.[formId]}
            expenseName={`Receipt #${index + 1} ${getAmount(
              fields[formId].amount,
              fields[formId].currency.label
            )}`}
            expandedId={expandedItemId}
            formId={formId}
            onOpen={setExpandedItemId}
            onRemove={handleRemove}
          >
            <CreateExpenseForm
              currencies={currenciesQuery.data.results}
              formId={formId}
              onSubmit={() => {}}
            />
          </CreateExpensePanel>
        ))}
        <Button
          data-testid="create-expense-modal.component-463DE4"
          size="small"
          priority="primary_black"
          onClick={handleAddNewForm}
        >
          + Receipt
        </Button>
        <NotificationBlock text="Note that reimbursements will be made in the currency of the worker's employment agreement regardless of the currency in the receipt(s)" />
      </ModalSide>

      {closeConfirm.value && (
        <Confirm
          title="Close window?"
          onYes={onClose}
          yesButtonProps={{ priority: 'primary' }}
          onCancel={closeConfirm.setFalse}
        >
          Changes that you made might not be saved <br /> Are you sure you want
          to close this window?
        </Confirm>
      )}
    </FormProvider>
  )
}
