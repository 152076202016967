import { styled } from '@mui/material'

const Root = styled('div')<{ isOver: boolean }>(({ theme, isOver }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  background: theme.palette.secondary[900],
  border: `${isOver ? 'solid' : 'dashed'} 1px`,
  borderRadius: theme.spacing(0.5),
  borderColor: isOver ? theme.palette.primary.main : '#B7C3CC',
}))

export const Styled = { Root }
