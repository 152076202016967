import './style.scss'

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { AttachmentUpload } from '@components/attachment/attachment-upload.component'
import { PAYROLL_STATUSES } from '@core/constants'
import { useApp } from '@core/context'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { setCurrencyChangeEvent } from '@core/utils'
import { Dialog, DialogContent } from '@mui/material'
import ChangesAccordion from '@pages/payrollsDetailPage/ChangesAccordion'
import { getCompaniesShort } from '@services/company.service'
import {
  customFxRateCreate,
  getCustomFxRateCreate,
} from '@services/custom-fx-rate.service'
import {
  approveFlowPayroll,
  fetchExchangeRateRecalculationStatus,
  getPayrollListCompany,
  getPayrollListNeedChange,
  postCalculateLastDay,
} from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import CalculatingProgress from './fx-loader-modal/fx-loader-modal.componen'
import { MultiCurrencyTable } from './multi-currency-table/multi-currency-table.component'

const FilterStates = [
  PAYROLL_STATUSES.AWAITING_PAYMENT,
  PAYROLL_STATUSES.CREATED,
  PAYROLL_STATUSES.PAID,
  PAYROLL_STATUSES.CANCELED,
]

export default function MultiCurrencyPage() {
  const { profile } = useApp()
  const location = useLocation()
  const history = useHistory()
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const queryParams = new URLSearchParams(location.search)
  const activeTab = queryParams.get('tab') || 'pending'

  const { params, setParams } = useSearchParams()
  const [attachments, setAttachments] = useState([])
  const [attachment, setAttachment] = useState(document?.attachment)
  const [loadingAttachment, setLoadingAttachment] = useState(false)
  const [customFxRate, setCustomFxRate] = useState()
  const [errorText, setErrorText] = useState()
  const [search, setSearch] = useState(params.get('search') || '')
  const [pollingTaskId, setPollingTaskId] = useState(null)
  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)

  const approvePayrollRequest = useMutation('approveFlowPayroll', {
    mutationFn: (payload) => approveFlowPayroll(payload),
    onSuccess: () => {
      toast.success('Successfully updated')
      refetch()
      refetchApproved()
    },
  })

  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })

  const { data: companiesData } = useQuery(['CompaniesShort'], {
    queryFn: () => getCompaniesShort({ limit: 1000 }),
  })

  const { data: getCustomFxRate } = useQuery(['getCustomFxRateCreate'], {
    queryFn: () => getCustomFxRateCreate(),
  })

  const startPolling = async (taskId) => {
    const poll = async () => {
      try {
        const response = await fetchExchangeRateRecalculationStatus({
          task_id: taskId,
        })

        if (!response) {
          throw new Error('No response received')
        }

        const { state } = response

        if (state === 'SUCCESS') {
          setPollingTaskId(null)
          toast.success('FX rate updated successfully')
          await refetch()
          return
        }

        if (state === 'FAILURE') {
          setPollingTaskId(null)
          toast.error('Failed to update FX rate')
          return
        }

        if (state === 'PENDING') {
          await new Promise((resolve) => {
            setTimeout(resolve, 1000)
          })
          poll()
          return
        }

        setPollingTaskId(null)
        toast.error('Unexpected status received')
      } catch (error) {
        setPollingTaskId(null)
        toast.error('Failed to check FX rate update status')
      }
    }

    await poll()
  }

  const customFxRateCreateMutation = useMutation({
    mutationFn: (payload) => customFxRateCreate(payload),
    onSuccess: (response) => {
      const taskId = response?.task_id || response?.id
      if (taskId) {
        setPollingTaskId(taskId)
        startPolling(taskId)
      } else {
        toast.error('No task ID received')
      }
    },
    onError: (error) => {
      toast.error('Failed to create custom FX rate')
    },
  })

  const {
    data: companyPayrollList,
    refetch,
    isFetching,
  } = useQuery(['getPayrollListCompany', activeTab, search], {
    queryFn: () =>
      getPayrollListCompany({ state: activeTab, company: search, limit: 1000 }),
  })

  const {
    data: companyApprovedPayrollList,
    refetch: refetchApproved,
    isFetching: isLoadingApproved,
  } = useQuery(['getApprovedPayrollListCompany'], {
    queryFn: () => getPayrollListCompany({ state: 'approved' }),
  })

  const { data: listNeedChange, isLoading: changeLoading } = useQuery(
    'payrollsListNeedChange',
    {
      queryFn: () => getPayrollListNeedChange(),
    }
  )

  const handleTabChange = (tab) => {
    queryParams.set('tab', tab)
    history.push({ search: queryParams.toString() })
  }

  const handleCompanyChange = (option) => {
    const val = option?.value || null
    setSearch(val)
    setParams({ search: val || '' })
  }

  const handleApproveAll = () => {
    approvePayrollRequest.mutate({ for_all: true })
  }

  const onSendFxRate = () => {
    if (attachment?.file_uuid) {
      toggleDelete()
      customFxRateCreateMutation.mutate({
        attachment_document: attachment.file_uuid,
        custom_fx_rate: customFxRate,
      })
    } else {
      setErrorText('File is required!')
    }
  }

  const handleAttach = (files) => {
    setErrorText(undefined)
    setAttachments(files)
  }

  const handleChangeFxRate = (e) => {
    setCustomFxRate(e.target.value)
  }

  const handleDelete = (fileId) => {
    setAttachments((prev) => prev.filter((id) => id !== fileId))
  }

  const checkDisable = () => {
    if (postCalculateLastDayMutate?.data?.last_day)
      return (
        currentDate <=
        new Date(
          moment(new Date(postCalculateLastDayMutate.data.last_day)).format(
            'YYYY-MM-DD'
          )
        )
      )
    return false
  }

  const fxRate =
    getCustomFxRate?.partner_custom_fx_rate?.custom_fx_rate ||
    getCustomFxRate?.remofirst_fx_rate
  const checkFxRateLocked = useMemo(() => {
    if (companyApprovedPayrollList?.data?.length > 0) {
      return true
    }
    return (
      companyPayrollList?.data?.some((company) =>
        company.payrolls?.some((payroll) => payroll.state !== 'created')
      ) || false
    )
  }, [companyApprovedPayrollList, companyPayrollList])

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.allowed_countries[0]?.id,
        days: 3,
      })
    }
  }, [profile])

  const fromCurrency =
    companyPayrollList?.partner_default_currency?.short_code ||
    companyPayrollList?.partner_default_currency?.sign ||
    ''
  const toCurrency =
    companyPayrollList?.partner_invoicing_currency?.short_code ||
    companyPayrollList?.partner_invoicing_currency?.sign ||
    ''

  return (
    <div className="customer-payroll">
      <div className="d-flex align-items-center mb-4">
        <Typography className="heading_semibold__24">Payroll</Typography>
      </div>
      <Tabs onTabChange={handleTabChange} selectedTab={activeTab}>
        <Tab tabId="pending" title="Pending">
          <div>
            <div className="d-flex mb-4 align-items-center gap-3 justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex align-items-center gap-3">
                  <div style={{ width: 250 }}>
                    <Select
                      placeholder="Select company"
                      value={search}
                      onChange={handleCompanyChange}
                      options={companiesData?.results?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: 190 }}>
                <Button
                  style={{ height: '32px' }}
                  size="small"
                  className="px-2 pl-0"
                  disabled={checkDisable()}
                  onClick={handleApproveAll}
                >
                  Approve All
                </Button>
              </div>
            </div>
            <div style={{ width: 'fit-content' }}>
              {/* Fxrate can not be 0 (Dair said) if will be 0 Dair promise to fix */}
              {!isFetching && companyPayrollList && fxRate && (
                <NotificationBlock
                  variant="blue"
                  render={
                    <Typography className="text_regular__14 ml-2">
                      The FX rate used is 1{' '}
                      {companyPayrollList?.partner_default_currency
                        ?.short_code ||
                        companyPayrollList?.partner_default_currency?.sign}{' '}
                      = {fxRate}{' '}
                      {companyPayrollList?.partner_invoicing_currency
                        ?.short_code ||
                        companyPayrollList?.partner_invoicing_currency?.sign}
                      {checkFxRateLocked ? (
                        '. This rate has been locked in since the first employee approval and cannot be changed.'
                      ) : (
                        <>
                          .{' '}
                          <Button
                            style={{
                              maxWidth: 'fit-content',
                              height: 22,
                              fontWeight: 700,
                              color: '#4C92C7',
                              textDecoration: 'underline',
                            }}
                            className="p-0"
                            priority="outlined"
                            size="small"
                            onClick={toggleDelete}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </Typography>
                  }
                />
              )}
            </div>
            <MultiCurrencyTable
              data={companyPayrollList}
              isLoading={isFetching}
              isDisabled={checkDisable()}
              refetch={refetch}
              refetchApproved={refetchApproved}
            />
          </div>
        </Tab>
        <Tab tabId="approved" title="Approved">
          <div>
            <div className="d-flex mb-4 align-items-center gap-3 justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div style={{ width: 250 }}>
                  <Select
                    placeholder="Select company"
                    value={search}
                    onChange={handleCompanyChange}
                    options={companiesData?.results?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isClearable
                  />
                </div>
              </div>
            </div>
            {!isFetching && companyPayrollList && checkFxRateLocked && (
              <div style={{ width: 'fit-content' }} className="mb-4">
                <NotificationBlock
                  variant="blue"
                  render={
                    <Typography className="text_regular__14 ml-2">
                      The FX rate used is 1{' '}
                      {companyPayrollList?.partner_default_currency
                        ?.short_code ||
                        companyPayrollList?.partner_default_currency?.sign}{' '}
                      = {fxRate}{' '}
                      {companyPayrollList?.partner_invoicing_currency
                        ?.short_code ||
                        companyPayrollList?.partner_invoicing_currency?.sign}
                      . This rate has been locked in since the first employee
                      approval and cannot be changed.
                    </Typography>
                  }
                />
              </div>
            )}
            <MultiCurrencyTable
              data={companyPayrollList}
              isLoading={isFetching}
              refetch={refetch}
              isApproved
            />
          </div>
        </Tab>
        <Tab
          tabId="change_requests"
          title="Requested Change"
          count={listNeedChange?.count}
        >
          {changeLoading && listNeedChange?.length === 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div>
              <ChangesAccordion
                month={listNeedChange?.month}
                payments={listNeedChange?.payrolls}
              />
            </div>
          )}
        </Tab>
      </Tabs>
      <EditModal
        visible={visibleDelete}
        title="FX rate"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <div className="mb-2">
          <CurrencyInput
            className="mb-2"
            isRequired
            decimalScale={9}
            value={fxRate}
            label="RemoFirst rate"
            disabled
          />
        </div>
        <div className="mb-2">
          <CurrencyInput
            isRequired
            decimalScale={9}
            label={`FX rate from ${fromCurrency} to ${toCurrency}`}
            placeholder="Enter the adjustment amount"
            onChange={setCurrencyChangeEvent(handleChangeFxRate)}
          />
        </div>
        <AttachmentUpload
          accept={{
            'image/*': ['.png', '.jpg', '.jpeg'],
            'application/pdf': ['.pdf'],
          }}
          customErrorText={errorText}
          isRequired
          onAttach={(fileIds) => handleAttach(fileIds)}
          onDelete={(fileId) => handleDelete(fileId)}
          setAttachment={setAttachment}
          setLoadingAttachment={setLoadingAttachment}
        />
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="PayrollDetailPage-FBE06F"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            data-testid="PayrollDetailPage-40DD71"
            priority="primary"
            size="small"
            loading={
              customFxRateCreateMutation.isLoading ||
              loadingAttachment ||
              pollingTaskId !== null
            }
            onClick={onSendFxRate}
          >
            Save
          </Button>
        </div>
      </EditModal>
      <Dialog open={customFxRateCreateMutation.isLoading}>
        <DialogContent>
          <CalculatingProgress companyCount={companyPayrollList?.data.length} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
